import React from 'react'
import useData from '../hooks/useData';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';

const UserRoute = ({children}) => {
    const { loader,token,profile } = useData();
    const location = useLocation();
  
    if (loader) {
      return <Loader/>;
    }
    if (!token) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    if (profile?.role_type?.name !== "User") {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
  };

export default UserRoute




