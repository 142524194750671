import React from "react";
import CategoryWiseProduct from "../../Components/CategoryWiseProduct/CategoryWiseProduct";
import Banner from "../../Components/Banner/Banner";
const Home = () => {
  return (
    <div className="raleway transition-all duration-1000 mt-14">
      <Banner />
      <CategoryWiseProduct />
    </div>
  );
};

export default Home;
