import React from 'react';

const Revenue = ({toggleDrawer,setDueModal}) => {
    return (
        <div style={{background:'#132d7f'}} className="p-4 rounded-2xl">
            <h1 className="font-bold text-white">
                Sales Report of{" "}
                <span className="">Rasel Store</span>
            </h1>
            <p className="text-sm text-gray-100 leading-6">
                Check revenue and profit using the selected date range here.
            </p>

            <button
                onClick={toggleDrawer}
                className="bg-blue-500 rounded-xl text-white font-semibold w-full mt-2 text-sm py-2 px-2"
            >
                View Total Revenue
            </button>
            <button
                onClick={() => setDueModal(true)}
                className="bg-white rounded-xl text-gray-800 font-semibold w-full mt-2 text-sm py-2 px-2"
            >
                View Due Amount
            </button>
        </div>
    );
};

export default Revenue;