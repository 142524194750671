import React from "react";

const MInput = ({label,func,place,type='text',readonly=false,name,value=null}) => {
    return (
        <div className="mb-4">
            <label className={'text-sm text-gray-600'}>{label}</label>
            <input
                readOnly={readonly}
                onChange={func}
                name={name}
                type={type}
                defaultValue={value}
                className="w-full focus:outline-blue-500 border p-2 rounded-xl bg-transparent text-sm"
                placeholder={place}
            />
        </div>
    )
}
export default MInput