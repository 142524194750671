const Error = ({error}) => {
    return (
        <div className={'p-8 w-full flex items-center justify-center text-center'}>
            <div>
                <img src={require("../../assets/error.png")} alt={'error'} className={'w-[250px] mx-auto'}/>
                <h1 className={'text-xl font-semibold text-teal-700'}>Failed to load data</h1>
                <p className={'text-red-500 text-sm'}>{error}</p>
            </div>
        </div>
    )
}
export default Error