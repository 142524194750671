import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import {MdAddCircle} from "react-icons/md";
import React, {useState} from "react";
import useData from "../../hooks/useData";
import MInput from "../../Components/MInput/MInput";
import MButton from "../../Components/MButton/MButton";


const ProfileInfo = () => {
    const {token, profile} = useData();
    const [profileData, setProfileData] = useState({});
    const [update, setUpdate] = useState(false);
    const handleProfileInput = (e) => {
        setProfileData({
            ...profileData,
            [e.target.name]:
                e.target.type === "file" ? e.target.files[0] : e.target.value,
        });
    };
    const updateProfile = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (const key in profileData) {
            const element = profileData[key];
            formData.append(`${key}`, element);
        }
        try {
            const res = await fetch(`${baseUrl.url}/api/profile`, {
                method: "POST",
                headers: {
                    Authorization: `bearer ${token}`,
                },
                body: formData,
            });
            if (res.ok) {
                successNotify("Profile updated successfully");
                setUpdate(!update);
                setProfileData({});
                e.target.reset();
            } else {
                errorNotify("Something went wrong!");
            }
        } catch (error) {
            errorNotify(error);
        }
    };
    return (
        <div className="p-2">
            <form onSubmit={updateProfile} className='w-full lg:w-3/12 bg-white mt-2'>
                <div className="mb-2 relative mx-auto h-36 w-36">
                    <div className="mx-auto h-36 w-36 border rounded-full overflow-hidden">
                        <img
                            src={
                                profileData.image_file
                                    ? URL.createObjectURL(profileData.image_file)
                                    : `${baseUrl.url}${profile?.imageURL}`
                            }
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="absolute bottom-2 right-0 bg-white rounded-full cursor-pointer">
                        <MdAddCircle size={35} className="text-blue-500"/>
                        <input
                            type="file"
                            onChange={handleProfileInput}
                            name="image_file"
                            className="w-full h-full opacity-0 absolute top-0 left-0 cursor-pointer"
                        />
                    </div>
                </div>
                <MInput value={profile?.username} type={'text'} label={'Username'} readonly={true}/>
                <MInput name={'name'} value={profileData?.name || profile?.name} type={'text'} func={handleProfileInput} place={'Profile Name'} label={'Name'}/>
                <MInput name={'phone'} value={profileData?.phone || profile?.phone} type={'text'} func={handleProfileInput} place={'Phone Number'} label={'Contact No'}/>
                <MButton type={'submit'} label={'Update'}/>
            </form>
        </div>
    )
}
export default ProfileInfo