import {useEffect, useState,useMemo} from "react";
import {baseUrl} from "../utils/helper";
import useData from "./useData";
export const useFetch = (endpoint,dependencies=[null]) => {
    const {token} = useData();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const memoizedFetchData = useMemo(() => {
        return async () => {
            try {
                setLoading(true);
                const res = await fetch(`${baseUrl.url}/${endpoint}`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Authorization": `bearer ${token}`
                    }
                });
                const resData = await res.json();
                if (res.ok && res.status !== 204) {
                    return resData.data;
                } else {
                    return [];
                }
            } catch (e) {
                setError(e.message);
                return [];
            } finally {
                setLoading(false);
            }
        };
    }, [endpoint, token]);

    useEffect(() => {
        const getData = async () => {
            const fetchData = await memoizedFetchData();
            const processedData = await fetchData;
            setData(processedData);
        };
        getData().then();
        // eslint-disable-next-line
    }, [memoizedFetchData,...dependencies]);

    return { loading, error, data };
};
