import React from "react";
import {dateTime, optionsGenerator, seriesGenerator} from "../../utils/helper";
import {AiFillStar} from "react-icons/ai";
import {BsFileEarmarkPerson} from "react-icons/bs";
import {useFetch} from "../../hooks/useFetch";
import ReactApexChart from "react-apexcharts"
import {GiMoebiusStar} from "react-icons/gi";
import {Skeleton} from "primereact/skeleton";
import PageHeading from "../../Components/PageHeading/PageHeading";

const Reviews = () => {

    const {data: reviews, loading: reviewLoading, error: reviewError} = useFetch("api/rating/report");
    const {
        data: remarks,
        loading: remarkLoading,
        error: remarkError
    } = useFetch("api/remarks?page=0&size=10&sort=-created_at");


    return (
        <div className="raleway mt-14 p-4">
            <PageHeading title={'Review Overview'} sub={'A list of the review and their rating.'}/>

            <div className="grid grid-cols-12 mt-3 gap-4">
                <div className={'col-span-12 lg:col-span-9'}>
                    {
                        !reviewError ? <>
                            {
                                !reviewLoading ? <>
                                    <div className={'grid grid-cols-1 gap-4'}>
                                        <div className={'grid grid-cols-12 border p-2'}>
                                            <div className={'col-span-12 lg:col-span-9'}>
                                                <ReactApexChart
                                                    options={optionsGenerator('Weekly Review', reviews?.weekly_rating)}
                                                    series={seriesGenerator(reviews?.weekly_rating)} type="bar"
                                                    height={350}/>
                                            </div>
                                            <div className={'col-span-12 lg:col-span-3'}>
                                                <div className="col-span-12 lg:col-span-4">
                                                    <div className="flex justify-between items-center pb-2 border-b">
                                                        <h1 className="text-sm font-bold text-gray-700">
                                                            Weekly Ratings
                                                        </h1>
                                                        <div className="flex justify-between gap-2">
                                    <span
                                        className="flex items-center justify-center text-sm font-bold text-gray-700">
                                      {reviews?.weekly_rating?.overall_rating.toFixed(2)}{" "}
                                        <AiFillStar className="text-orange-500" size={20}/>
                                    </span>
                                                        </div>
                                                    </div>
                                                    <ul className="p-2 space-y-1">
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Air
                                                                    conditioner and lighting </p>
                                                                <span className="flex items-center justify-center">
                                                {reviews?.weekly_rating?.air_conditioner_and_lighting.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Balcony
                                                                    cleanliness </p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.balcony_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Behaviourand
                                                                    attitude</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.behavior_and_attitude.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Food and
                                                                    beverage</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.food_and_beverage.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>{" "}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>In house
                                                                    store service</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.in_house_store_service.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Kitchen
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.kitchen_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Office
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.office_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Pest
                                                                    control</p>
                                                                <span className="flex items-center justify-center">
                                          {reviews?.weekly_rating?.pest_control.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                        </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Restroom
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.restroom_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Service
                                                                    promptness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.weekly_rating?.service_promptness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'grid grid-cols-12 border p-2'}>
                                            <div className={'col-span-12 lg:col-span-9'}>
                                                <ReactApexChart
                                                    options={optionsGenerator('Monthly Review', reviews?.monthly_rating)}
                                                    series={seriesGenerator(reviews?.monthly_rating)} type="bar"
                                                    height={350}/>
                                            </div>
                                            <div className={'col-span-12 lg:col-span-3'}>
                                                <div className="col-span-12 lg:col-span-4">
                                                    <div className="flex justify-between items-center pb-2 border-b">
                                                        <h1 className="text-sm font-bold text-gray-700">
                                                            Monthly Ratings
                                                        </h1>
                                                        <div className="flex justify-between gap-2">
                                    <span
                                        className="flex items-center justify-center text-sm font-bold text-gray-700">
                                      {reviews?.monthly_rating?.overall_rating.toFixed(2)}{" "}
                                        <AiFillStar className="text-orange-500" size={20}/>
                                    </span>
                                                        </div>
                                                    </div>
                                                    <ul className="p-2 space-y-1">
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Air
                                                                    conditioner and lighting </p>
                                                                <span className="flex items-center justify-center">
                                                {reviews?.monthly_rating?.air_conditioner_and_lighting.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Balcony
                                                                    cleanliness </p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.balcony_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Behaviourand
                                                                    attitude</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.behavior_and_attitude.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Food and
                                                                    beverage</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.food_and_beverage.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>{" "}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>In house
                                                                    store service</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.in_house_store_service.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Kitchen
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.kitchen_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Office
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.office_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Pest
                                                                    control</p>
                                                                <span className="flex items-center justify-center">
                                          {reviews?.monthly_rating?.pest_control.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                        </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Restroom
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.restroom_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Service
                                                                    promptness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.monthly_rating?.service_promptness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'grid grid-cols-12 border p-2'}>
                                            <div className={'col-span-12 lg:col-span-9'}>
                                                <ReactApexChart
                                                    options={optionsGenerator('Overall Review', reviews?.overall_rating)}
                                                    series={seriesGenerator(reviews?.overall_rating)} type="bar"
                                                    height={390}/>
                                            </div>
                                            <div className={'col-span-12 lg:col-span-3'}>
                                                <div className="col-span-12 lg:col-span-4">
                                                    <div className="flex justify-between items-center pb-2 border-b">
                                                        <h1 className="text-sm font-bold text-gray-700">
                                                            Overall Ratings
                                                        </h1>
                                                        <div className="flex justify-between gap-2">
                                    <span
                                        className="flex items-center justify-center text-sm font-bold text-gray-700">
                                      {reviews?.overall_rating?.overall_rating.toFixed(2)}{" "}
                                        <AiFillStar className="text-orange-500" size={20}/>
                                    </span>
                                                        </div>
                                                    </div>
                                                    <ul className="p-2 space-y-1">
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Air
                                                                    conditioner and lighting </p>
                                                                <span className="flex items-center justify-center">
                                                {reviews?.overall_rating?.air_conditioner_and_lighting.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Balcony
                                                                    cleanliness </p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.balcony_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Behaviourand
                                                                    attitude</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.behavior_and_attitude.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Food and
                                                                    beverage</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.food_and_beverage.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>{" "}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>In house
                                                                    store service</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.in_house_store_service.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Kitchen
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.kitchen_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Office
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.office_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Pest
                                                                    control</p>
                                                                <span className="flex items-center justify-center">
                                          {reviews?.overall_rating?.pest_control.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                        </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Restroom
                                                                    cleanliness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.restroom_cleanliness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-base flex items-center">
                                                                    <GiMoebiusStar
                                                                        size={15}
                                                                        className="mr-2"/>Service
                                                                    promptness</p>
                                                                <span className="flex items-center justify-center">
                                              {reviews?.overall_rating?.service_promptness.toFixed(2)}{" "}
                                                                    <AiFillStar className="text-orange-500" size={20}/>
                                            </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : <div>
                                    <div className={'grid grid-cols-12 gap-4 mb-2'}>
                                        <div className={'col-span-12 lg:col-span-9'}>
                                            <Skeleton width="100%" height="300px" className="mb-2"></Skeleton>
                                        </div>
                                        <div className={'col-span-12 lg:col-span-3'}>
                                            <Skeleton width="100%" height="300px" className="mb-2"></Skeleton>
                                        </div>
                                    </div>
                                    <div className={'grid grid-cols-12 gap-4 mb-2'}>
                                        <div className={'col-span-12 lg:col-span-9'}>
                                            <Skeleton width="100%" height="300px" className="mb-2"></Skeleton>
                                        </div>
                                        <div className={'col-span-12 lg:col-span-3'}>
                                            <Skeleton width="100%" height="300px" className="mb-2"></Skeleton>
                                        </div>
                                    </div>
                                    <div className={'grid grid-cols-12 gap-4'}>
                                        <div className={'col-span-12 lg:col-span-9'}>
                                            <Skeleton width="100%" height="300px" className="mb-2"></Skeleton>
                                        </div>
                                        <div className={'col-span-12 lg:col-span-3'}>
                                            <Skeleton width="100%" height="300px" className="mb-2"></Skeleton>
                                        </div>
                                    </div>
                                </div>
                            }
                        </> : "Error loading data"
                    }
                </div>
                <div className="col-span-12 lg:col-span-3 border  h-fit lg:h-[127.6vh] overflow-auto">
                    <div className="p-2 border-b">
                        <h1 className="text-sm font-bold text-gray-800">
                            Remarks
                        </h1>
                    </div>
                    {
                        !remarkError ? <>
                            {
                                !remarkLoading ? <div className="p-2 bg-gray-50">
                                    {remarks?.items?.map((mark, i) => {
                                        return (
                                            <div key={i} className="w-full rounded mb-2">
                                                <div className="flex justify-between">
                                                    <div className="flex">
                                                        <div
                                                            className="w-10 h-10 rounded-full overflow-hidden shadow bg-white flex items-center justify-center">
                                                            <BsFileEarmarkPerson/>
                                                        </div>
                                                        <div className="ml-2">
                                                            <h1 className="text-sm">Anonymous</h1>
                                                            <p className="text-xs">Works at Selopia</p>
                                                        </div>
                                                    </div>
                                                    <span className="text-xs">
                      {dateTime(mark?.created_at)}
                    </span>
                                                </div>
                                                <div
                                                    className="mt-2 p-4 pt-6 bg-gray-200"
                                                    style={{
                                                        clipPath:
                                                            "polygon(7% 18%, 11% 0, 15% 18%, 100% 18%, 100% 100%, 0 100%, 0 18%)",
                                                    }}
                                                >
                                                    <p className="text-sm">{mark?.optional_remarks}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div> : <Skeleton width="100%" height="100%" className="mb-2"></Skeleton>
                            }
                        </> : `Error loading data ${remarkError}`
                    }

                </div>
            </div>

        </div>
    );
};

export default Reviews;
