import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {baseUrl} from "../../utils/helper";

export const ordersApi = createApi({
        reducerPath: 'orderApi',
        baseQuery: fetchBaseQuery({ baseUrl: baseUrl.url }),
        tagTypes:['Orders'],
        endpoints: (builder) => ({
            getOrders: builder.query({
                query: (arg) => ({
                    url:arg.url,
                    method:"GET",
                    credentials:"include",
                    headers:{
                        "Authorization":`bearer ${arg.token}`
                    }
                }),
                providesTags:['Orders']
            }),
            changeOrderStatus:builder.mutation({
                query:(arg) =>({
                    url:arg.url,
                    method:"PUT",
                    headers:{
                        "Authorization":`bearer ${arg.token}`
                    },
                    body:arg.data
                }),
                invalidatesTags:['Orders']
            })
    }),
    })

export const { useGetOrdersQuery,useChangeOrderStatusMutation } = ordersApi