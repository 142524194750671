import React from "react";
import useData from "../../hooks/useData";
import { errorNotify, successNotify} from "../../utils/helper";
import {BsSuitHeart, BsSuitHeartFill} from "react-icons/bs";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "../../Components/Error/Error"
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import Loader from "../../Components/Loader/Loader";
import Empty from "../../Components/Empty/Empty";
import AddProductsLoader from "../AddProduct/AddProductsLoader"
import PageHeading from "../../Components/PageHeading/PageHeading";
import {
  useGetReactMutation,
  useGetRequestQuery,
  useUpdateRequestStatusMutation
} from "../../rtk/api/stockApi";

const StockList = ({ update, setUpdate,open,setOpen }) => {
  const { token, profile } = useData();
  const {data,isLoading:loading,isError:error} = useGetRequestQuery({url:"api/product_restock_request?page=0&size=100&sort=-created_at",token:token})
  const [updateStatus] = useUpdateRequestStatusMutation();
  const [updateReact] = useGetReactMutation();

  const handleStatus = async (id, status) => {
    const postData = {
      is_accepted: Number(status),
    };
    try {
      await updateStatus({url:`/api/product_restock_request/${id}`,data:JSON.stringify(postData),token:token}).unwrap();
      successNotify("Product request status updated successfully!");
    } catch (error) {
      errorNotify("Product request status updated failed!");
    }
  };

  const getReact = async (id) => {
    try {
      await updateReact({url:`/api/product_restock_react/${id}`,token:token}).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  //prime react
  const statuses = [
    { name: "Approved", code: 1 },
    { name: "Rejected", code: 9 },
  ];

  const statusTemplate = (data) => {
    const status = data?.is_accepted;
    const id = data?.id;

    return (
      <div>
        {(profile?.role_type?.name === "Admin" || profile?.role_type?.name === "Super Admin") ? (
          <>
            <div>
              <select
                value={status}
                onChange={(e) => handleStatus(id, e.target.value)}
                className="bg-white focus:p-1 focus:outline-slate-500 dark:bg-slate-800 cursor-pointer"
                disabled={status === 1}
              >
                {statuses?.map((status, i) => (
                  <option key={i} value={status?.code}>
                    {status?.name}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <div>
            <p
              className={`text-xs w-24 text-center p-[3px] px-[5px] text-white rounded-full text-white ${
                status === 0
                  ? "bg-orange-400"
                  : status === 1
                  ? "bg-green-500"
                  : "bg-red-500"
              }`}
            >
              {status === 0
                ? "Not Approved"
                : status === 1
                ? "Confirmed"
                : "Rejected"}
            </p>
          </div>
        )}
      </div>
    );
  };

  const NameTemplate = (data) => {
    const name = data?.requested_by?.name;
    return <span>{name}</span>;
  };
  const ReactTemplate = (data) => {
    const isReact = data?.is_reacted;
    const reacted = data?.reacts;
    return (
      <div className="relative text-left flex items-center cursor-pointer">
        {isReact !== null ? (
          <BsSuitHeartFill
            className="text-red-500 heart-react"
            onClick={() => getReact(data?.id)}
            size={20}
          />
        ) : (
          <BsSuitHeart
            className="text-red-500"
            onClick={() => getReact(data?.id)}
            size={20}
          />
        )}

        <span className="ml-1 text-xs">{reacted?.length}</span>
      </div>
    );
  };
  if (loading) {
    return <Loader/>;
    
  }
  return (
    <div className="raleway">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {
        !error ? <>
          {
            !loading ? <div>
                <PageHeading title={'Product Stock Request List'} sub={'A list of the requested products and their status.'}/>
              {data?.data?.items?.length > 0 ? (
                  <div className="mt-3">
                    <DataTable
                        value={data?.data?.items}
                        size={"small"}
                        dataKey="id"
                        tableStyle={{ minWidth: "50rem" }}
                        sortMode="multiple"
                        paginator
                        rows={17}
                        rowsPerPageOptions={[17, 5, 10, 25, 50]}
                    >
                      <Column header="Name" field="name" className="" />
                      <Column
                          header="Daily Requirement"
                          field="daily_requirement"
                          className=""
                      />
                      {profile?.role_type?.name==="Admin" && <Column header="Requested By" body={NameTemplate} className="" />}
                      <Column header="Status" body={statusTemplate} className="" />
                      <Column header="React" body={ReactTemplate} className="" />
                    </DataTable>
                  </div>
              ) : (
                  <Empty/>
              )}
            </div> : <AddProductsLoader/>
          }
        </> : <Error error={error}/>
      }
    </div>
  );
};

export default StockList;
