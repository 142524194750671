import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const usersApi = createApi({
    reducerPath:'usersApi',
    baseQuery:fetchBaseQuery({baseUrl: process.env.REACT_APP_DOMAIN}),
    tagTypes:['Users'],
    endpoints:(builder)=>({
        getUsers:builder.query({
            query:(arg) =>({
                url:arg.url,
                method:"GET",
                credentials: "include",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                }
            }),
            providesTags:['Users']
        }),
        addUser:builder.mutation({
            query:(arg) =>({
                url:arg.url,
                method:"POST",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                },
                body:arg.data
            }),
            invalidatesTags:['Users']
        }),
        updateUser:builder.mutation({
            query:(arg) =>({
                url:arg.url,
                method:"POST",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                },
                body:arg.data
            }),
            invalidatesTags:['Users']
        }),
        deleteUser:builder.mutation({
            query:(arg) =>({
                url:arg.url,
                method:"DELETE",
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `bearer ${arg.token}`
                },
                body:arg.data
            }),
            invalidatesTags:['Users']
        }),
    })

})
export const {useGetUsersQuery,useAddUserMutation,useUpdateUserMutation,useDeleteUserMutation} = usersApi