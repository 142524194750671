import React, {useState} from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import {Outlet} from "react-router-dom";
import Header from "../../Components/Header/Header";
const Dashboard = () => {
  const [show, setShow] = useState(true);
  return (
    <div>
      <Header show={show} setShow={setShow} />
      <Sidebar show={show} setShow={setShow} />
      <div
        className={`transition-all duration-300 ${
          show ? "ml-0 lg:ml-72" : "ml-0"
        }`}
      >
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default Dashboard;
