import React, {useState} from "react";
import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import useData from "../../hooks/useData";
import {useNavigate} from "react-router-dom";
import MInput from "../../Components/MInput/MInput";
import MButton from "../../Components/MButton/MButton";

const PasswordUpdate = () => {
    const {token} = useData();
    const [inputData, setInputData] = useState({});
    const navigate = useNavigate();

    const resetPassword = async (e) => {
        e.preventDefault();
        if (
            !inputData.previous_password ||
            inputData.previous_password.length < 5
        ) {
            errorNotify("Previous PasswordUpdate shouldbe minimum 6 character!");
            return;
        }
        if (!inputData.password || inputData.password.length < 5) {
            errorNotify("PasswordUpdate shouldbe minimum 6 character!");
            return;
        }
        if (!inputData.confirm_password || inputData.confirm_password.length < 5) {
            errorNotify("Confirm PasswordUpdate shouldbe minimum 6 character!");
            return;
        }
        if (inputData.password !== inputData.confirm_password) {
            errorNotify("Passwords do not match!");
        }
        const res = await fetch(`${baseUrl.url}/api/reset_password`, {
            method: "PUT",
            headers: {
                Authorization: `bearer ${token}`,
            },
            body: JSON.stringify(inputData),
        });
        const resData = await res.json();
        if (res.ok) {
            successNotify('Password Updated Successfully');
            setInputData({});
            e.target.reset();
            setTimeout(() => {
                navigate("/login");
            }, 3000);
        } else {
            errorNotify(resData.errors[0]);
        }
    };
    const handleInput = (e) => {
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="p-2">
            <form onSubmit={resetPassword} className='w-full lg:w-3/12 bg-white mt-2'>
                <MInput type={'password'} name={'previous_password'} value={inputData.previous_password} place={'Current Passowrd'} label={'Current Password'} func={handleInput}/>
                <MInput type={'password'} name={'password'} value={inputData.password} place={'New Passowrd'} label={'New Password'} func={handleInput}/>
                <MInput type={'password'} name={'confirm_password'} value={inputData.confirm_password} place={'Confirm Passowrd'} label={'Confirm Password'} func={handleInput}/>
                <MButton type={'submit'} label={'Update'} />
            </form>
        </div>
    )
}
export default PasswordUpdate