import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const stockApi = createApi({
    reducerPath:'stockApi',
    baseQuery:fetchBaseQuery({baseUrl: process.env.REACT_APP_DOMAIN}),
    tagTypes:['Stock'],
    endpoints:(builder)=>({
        getRequest:builder.query({
            query:(arg) =>({
                url:arg.url,
                method:"GET",
                credentials: "include",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                }
            }),
            providesTags:['Stocks']
        }),
        addRequest:builder.mutation({
            query:(arg) =>({
                url:arg.url,
                method:"POST",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                },
                body:arg.data
            }),
            invalidatesTags:['Stocks']
        }),
        updateRequestStatus:builder.mutation({
            query:(arg) =>({
                url:arg.url,
                method:"PUT",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                },
                body:arg.data
            }),
            invalidatesTags:['Stocks']
        }),
        getReact:builder.mutation({
            query:(arg) =>({
                url:arg.url,
                method:"GET",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                },
            }),
            invalidatesTags:['Stocks']
        }),
    })

})
export const {useGetRequestQuery,useGetReactMutation,useUpdateRequestStatusMutation,useAddRequestMutation} = stockApi