import React from "react";
import useData from "../../hooks/useData";
import {useNavigate} from "react-router-dom";

export const ReviewNavigation = ({show, setShow}) => {
    const {profile} = useData();
    const navigate = useNavigate()
    return (
        <>
            {profile?.role_type?.name !== "Admin" && (
                <div className="p-4 bg-gray-50 rounded-xl text-gray-800">
                    <h1 className="font-bold">
                        <i>
                            Review for <span className="">Rasel</span>
                        </i>

                    </h1>
                    <p className="text-sm  leading-6">
                        <i>Based on your last week experience, please rate your satisfaction
                            with the following aspects.</i>
                    </p>
                    <button
                        onClick={() => {
                            if (window.innerWidth < 500) {
                                setShow(false);
                            }
                            navigate(profile?.is_reviewed === false ? "/add-review" : "#");
                        }}
                        className={`block text-center text-sm mt-2 text-white bg-blue-800 rounded-xl w-full py-2 px-2 ${profile?.is_reviewed === false ? "" : "cursor-not-allowed"}`}
                    >
                        {
                            profile?.is_reviewed === false ? "Review Now" : "You reviewed this week"
                        }
                    </button>
                    <button
                        className="bg-blue-500 p-2 rounded-xl text-white text-sm px-2 w-full mt-2"
                        onClick={() => {
                            if (window.innerWidth < 500) {
                                setShow(false);
                            }
                            navigate("/products");
                        }}
                    >
                        All Products
                    </button>
                </div>
            )}
        </>
    )
}
