import React from 'react'
import useData from '../hooks/useData';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';

const CommonRoute = ({children}) => {
    const { loader,token,
        profile
    } = useData();
    const location = useLocation();
    if (loader && !token) {
        return <Loader/>;
    }
    if (!token && !loader && !profile?.role_type?.name) {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
};

export default CommonRoute