import React from "react";

const Loader = () => {
  return (
    <div className="w-full h-screen flex items-center justify-center fixed top-0 left-0">
      <div>
        <h1 className="text-center">Calm Down</h1>
        <img src={require("./rasel.png")} alt="" className="w-40 h-12" />
        <div className="absolute w-full bottom-4 left-0 text-center">
          <h1 className="flex items-center justify-center text-gray-700">
            Loading...
            <img src="https://i.gifer.com/ZKZg.gif" alt="" className="w-5 h-5" />
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Loader;
