import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {productsApi} from "../api/productsApi";
import {usersApi} from "../api/usersApi";
import {ordersApi} from "../api/ordersApi";
import {stockApi} from "../api/stockApi";


export const store = configureStore({
    reducer: {
        [productsApi.reducerPath]: productsApi.reducer,
        [usersApi.reducerPath]:usersApi.reducer,
        [ordersApi.reducerPath]:ordersApi.reducer,
        [stockApi.reducerPath]:stockApi.reducer
    },
    middleware: (mid) =>[...mid(),productsApi.middleware,usersApi.middleware,ordersApi.middleware,stockApi.middleware],
})
setupListeners(store.dispatch)