import {toast} from "react-toastify";
export const baseUrl = {
    url: process.env.REACT_APP_DOMAIN
}
export const optionsGenerator = (title, data) => {
    let names = []
    for (const key in data) {
        // let name = key.split("_")
        if (key !== 'created_at' && key !== 'updated_at' && key !== 'optional_remarks')
            names.push(key)
        // names.push(`${name[0]} ${name[1]}`)
    }
    const colors = ['#FF5733', '#33FF57', '#3366FF', '#FF33DD', '#FFFF33', '#33FFFF', '#333333', '#FF3366'];
    return {
        chart: {
            height: 350,
            type: 'bar',
        },
        title: {
            text: title,
            align: 'left'
        },

        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            }
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: names,
            labels: {
                style: {
                    // colors: colors,
                    fontSize: '9px'
                }
            }
        }
    }

}
export const seriesGenerator = (data) => {
    let series = [
        {
            data: []
        }
    ]
    for (const key in data) {
        if (key !== 'created_at' && key !== 'updated_at' && key !== 'optional_remarks') series[0].data?.push(Math.ceil(data[key]))
    }
    return series
}
export const successNotify = (msg) => {
    toast.success(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};
export const errorNotify = (msg) => {
    toast.error(msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};
export const dateTime = (inputTimestamp) => {
    const dateObj = new Date(inputTimestamp);

    const options = {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };

    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
};
const colors = ['bg-red-500/50', 'bg-green-500/50', 'bg-blue-500/50', 'bg-yellow-500/50', 'bg-orange-500/50', 'bg-indigo-500/50', 'bg-teal-500/50', 'bg-purple-500/50']; // Add as many colors as needed
export const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
};
export const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    if (timestamp?.length <= 0) {
        return <span className="text-red-500">No data</span>
    }
    const timeDifferenceInSeconds = Math.floor((now - date) / 1000);

    if (timeDifferenceInSeconds < 60) {
        return "Just now";
    }

    const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60);

    if (timeDifferenceInMinutes < 60) {
        return `${timeDifferenceInMinutes} minute${
            timeDifferenceInMinutes > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60);

    if (timeDifferenceInHours < 24) {
        return `${timeDifferenceInHours} hour${
            timeDifferenceInHours > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24);

    if (timeDifferenceInDays < 30) {
        return `${timeDifferenceInDays} day${
            timeDifferenceInDays > 1 ? "s" : ""
        } ago`;
    }

    const timeDifferenceInMonths = Math.floor(timeDifferenceInDays / 30);

    if (timeDifferenceInMonths < 12) {
        return `${timeDifferenceInMonths} month${
            timeDifferenceInMonths > 1 ? "s" : ""
        } ago`;
    }
    const timeDifferenceInYears = Math.floor(timeDifferenceInMonths / 12);
    return `${timeDifferenceInYears} year${
        timeDifferenceInYears > 1 ? "s" : ""
    } ago`;

}
