import rimg from "./r.jpg";
import React, {useState} from "react";
// import { SlReload } from "react-icons/sl";
import useData from "../../hooks/useData";
import {baseUrl, formatTimeAgo} from "../../utils/helper";
import {useFetch} from "../../hooks/useFetch";


const RaselInfo = () => {
    const {profile,token} = useData();
    const [update, setUpdate] = useState(false);
    const {data} = useFetch('api/admin_status',[update]);

    const updateOnlineStatus = async ()=>{
        if (profile?.role_type?.name !== "Admin"){
            return
        }
        const res = await fetch(`${baseUrl.url}/api/profile/is_online`,{
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `bearer ${token}`
            }
        });
        if(res.ok){
            setUpdate(!update)
        }
    }
    return (
        <div className="mb-2">
            <div className="flex justify-between p-2 rounded mb-2">
                <div className="flex items-center ">
                    {<div onClick={updateOnlineStatus}
                          className={`cursor-pointer h-10 w-10 border-2 rounded-full overflow-hidden ${data?.admin_is_online === 1 ? 'border-green-500' : 'border-red-500'}`}>
                        <img src={rimg} alt="" className="w-full h-full object-cover"/>
                    </div>}
                    <div className="ml-2 text-gray-800">
                        <h1>Md Rasel<p className="text-xs text-indigo-500 ml-1 inline">[Ground Ninja]</p></h1>
                        <p className={'text-xs -mt-1'}>{formatTimeAgo(data?.admin_last_online)}</p>
                    </div>
                </div>
                {/*<button onClick={()=>setUpdate(!update)} className={'text-sm flex items-center'}><SlReload size={15}/></button>*/}
            </div>
        </div>
    )
}
export default RaselInfo