import PageHeading from "../../Components/PageHeading/PageHeading";
import React, {useState} from "react";
import useData from "../../hooks/useData";
import {useFetch} from "../../hooks/useFetch";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Paginator} from 'primereact/paginator';
import AddProductsLoader from '../AddProduct/AddProductsLoader'
import Error from '../../Components/Error/Error'
import {FaRegUser} from "react-icons/fa";
import ProfileInfo from "./ProfileInfo";
import PasswordUpdate from "./PasswordUpdate";
import { IoLockOpenOutline ,IoNewspaperOutline} from "react-icons/io5";
const Profile = () => {
    const {profile} = useData();

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(16);
    const [selected, setSelected] = useState("Profile");
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    let pageNum = ((first / rows));

    const {
        data,
        loading,
        error
    } = useFetch(`api/product_purchase/completed?page=${pageNum}&size=${rows}&sort=-created_at`);

    const tabs = [{icon:<FaRegUser/>,text:"Profile"},{icon:<IoLockOpenOutline/>,text:"Security"}]
    return <div className='raleway mt-14 p-4'>

        <div>
            <PageHeading title={'Profile'} sub={'Profile,Security and Purchase history here'}/>
            {
                <div className={'flex items-center'}>
                    {tabs.map((item, i) => {
                        return (
                            <button
                                className={`py-1 lg:py-2 px-2 lg:px-3 text-sm lg:text-base flex items-center ${
                                    selected === item.text
                                        ? "text-blue-500 border-b-2 border-blue-400"
                                        : "text-gray-700"
                                }`}
                                onClick={() => setSelected(item.text)}
                                key={i}
                            >   {item.icon}
                                <span className='ml-2'>{item.text}</span>
                            </button>
                        );
                    })}
                    {profile?.role_type?.name==='User' && <button
                        className={`py-1 lg:py-2 px-2 lg:px-3 text-sm lg:text-base flex items-center ${
                            selected === "Purchase History"
                                ? "text-blue-500 border-b-2 border-blue-400"
                                : "text-gray-700"
                        }`}
                        onClick={() => setSelected("Purchase History")}
                    ><IoNewspaperOutline/>
                        <span className='ml-2'>Purchase History</span>
                    </button>}
                </div>
            }
            {selected==='Profile' && <ProfileInfo/>}
            {selected==='Security' && <PasswordUpdate/>}
            {(selected==='Purchase History' && profile?.role_type?.name==='User') && <div>
                {
                    !error ? <>
                        {
                            !loading ? <div>

                                {profile?.role_type?.name === "User" && (
                                    <div className="rounded mt-4">
                                        <div className={'flex items-center mb-2'}>
                                            <div className={'bg-blue-500 text-white text-sm mr-1 rounded-xl p-2'}>Total Order
                                                : {profile?.total_complete_order}</div>
                                            <div className={'bg-teal-500 text-white text-sm mr-1 rounded-xl p-2'}>Total Cost
                                                : {profile?.total_purchased_amount}</div>
                                            <div className={'bg-cyan-500 text-white text-sm mr-1 rounded-xl p-2'}>Total Due
                                                : {profile?.total_due_amount}</div>
                                        </div>
                                        {data?.items?.length > 0 ? (
                                            <div>
                                                <DataTable size={'small'} value={data.items}
                                                           tableStyle={{minWidth: '50rem'}}>
                                                    <Column body={(data) => data.product.name}
                                                            header="Product Name"></Column>
                                                    <Column body={(data) => {
                                                        return <span>
                                            {data?.product.quantity < 10 && 0}
                                                            {data?.quantity}
                                        </span>
                                                    }} header="Quantity"></Column>
                                                    <Column body={(data) => {
                                                        return <span>
                                            {data?.product.price < 10 && 0}
                                                            {data?.product.price}
                                        </span>
                                                    }} header="Price"></Column>
                                                </DataTable>
                                                <Paginator first={first} rows={rows}
                                                           totalRecords={data?.last_page * rows}
                                                           rowsPerPageOptions={[16, 26, 36]}
                                                           onPageChange={onPageChange}/>
                                            </div>
                                        ) : (
                                            <div className="p-4">
                                                <img
                                                    src={require("../../assets/no-content.png")}
                                                    alt=""
                                                    className="w-10 h-10 mx-auto"
                                                />
                                                <p className="text-gray-700 text-sm text-center mt-2">
                                                    No data available. <br/> Start ordering!
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                )}

                            </div> : <AddProductsLoader/>
                        }
                    </> : <Error error={error}/>
                }
            </div>}

            </div>
    </div>
}
export default Profile