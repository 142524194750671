import React, {useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {errorNotify, successNotify} from "../../utils/helper";
import useData from "../../hooks/useData";
import StockList from "./StockList";
import PageHeading from "../../Components/PageHeading/PageHeading";
import MButton from "../../Components/MButton/MButton";
import {useAddRequestMutation} from "../../rtk/api/stockApi";
import MInput from "../../Components/MInput/MInput";

const StockRequest = () => {
  const {token} = useData();
  const [reqData, setReqData] = useState({});
  const { update, setUpdate } = useData();
  const [addStorckRequest] = useAddRequestMutation()
  //HANDLE INPUTE STATE ADD
  const handleInput = (e) => {
    setReqData({
      ...reqData,
      [e.target.name]: e.target.value,
    });
  };
  //ADD FOR STOCK REQUEST
  const addRequest = async (e) => {
    e.preventDefault();
    const postData = {
      name: reqData?.name,
      daily_requirement: Number(reqData?.requirment),
    };
    try {
      await addStorckRequest({url:'api/product_restock_request',token:token,data:JSON.stringify(postData)}).unwrap();
      successNotify("Stock Request placed successfully");
      setReqData({});
      e.target.reset();
    }catch (e) {
      errorNotify(e.message)
    }
  };
  return (
    <div className="raleway mt-14">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 lg:col-span-9 order-2 lg:order-1">
        <div className="p-4">
          <StockList update={update} setUpdate={setUpdate} />
        </div>
        </div>
        <div className="col-span-12 lg:col-span-3 order-1 lg:order-2">
          <div className="p-4 lg:border-l h-auto lg:h-[92vh]">
            <PageHeading title={'Product Stock Form'} sub={'A request for the inventory to include a new product.'}/>
            <form onSubmit={addRequest} className="mt-4">
              <MInput name={'name'} label={'Product Name'} func={handleInput} type={'text'} place={'Start from here'}/>
              <MInput name={'requirment'} label={'Daily Requirement'} func={handleInput} type={'number'} place={'Home many you need'}/>
              <MButton type={'submit'} label={"Request"}/>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockRequest;
