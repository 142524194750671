import "./App.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router/Router";
import { useEffect } from "react";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { Capacitor } from "@capacitor/core";
import useData from "./hooks/useData";
function App() {
  const { profile } = useData();
    if (Capacitor.getPlatform() === "web" && profile?.role_type?.name && 'Notification' in window) {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: process.env.REACT_APP_INSTANCEID,
      });
      beamsClient
          .start()
          .then(() => beamsClient.getDeviceId())
          .then(() => beamsClient.addDeviceInterest(profile?.role_type?.name==="Super Admin" ? "Admin" : profile?.role_type?.name))
          .then(() =>
              console.log(
                  `Successfully registered and subscribed! ${profile?.role_type?.name}`
              )
          )
          .catch((error) => console.error("Could not get device id", error));
    }

  useEffect(() => {
    window.onfocus = function () {
      document.title = "Rasel Store";
    };
    window.onblur = function () {
      document.title = "Come Down go to store !!!!!";
    };
  }, []);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
