import React from "react";

const Design = () => {
    return (
        <div className={'absolute bottom-0 left-0 object-cover z-10 grayscale'}>
            {/*<img alt={'mosque'} className={'sepia w-8/12'} style={{transform:'scaleX(-1)'}} src={require("../../assets/design.png")}/>*/}
            <span className={'text-xs py-1 px-2 text-black whitespace-nowrap'}>Version 1.10.0</span>
        </div>
    )
}
// up
export default Design