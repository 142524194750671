import React from "react";

export const ProductLoader = () => {
    return (
        <div>
            <div className="w-full h-28 rounded-xl bg-gray-300"></div>
            <div className="w-full h-4 rounded-xl bg-gray-300 mt-2"></div>
            <div className="grid grid-cols-2 gap-2 items-center space-y-2 mb-2">
                <div className="h-4 rounded-xl bg-gray-300"></div>
                <div className="h-4 rounded-xl bg-gray-300"></div>
            </div>
            <div className="w-full h-4 rounded-xl bg-gray-300"></div>
        </div>
    )
}
