import React from "react";
import { BsEmojiSmile } from "react-icons/bs";

const Empty = () => {
  return (
    <div className="h-[73vh] w-full top-0 left-0 flex items-center justify-center">
      <div className="text-center">
        <div className="flex items-center justify-center">
          <BsEmojiSmile size={40} className="text-center text-gray-500" />
        </div>
        <h1 className="text-xl text-gray-700">No data found</h1>
        <p className="text-sm">It seems like no date here</p>
      </div>
    </div>
  );
};

export default Empty;
