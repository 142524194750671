import {Skeleton} from "primereact/skeleton";
import React from "react";

const AddProductsLoader = () => {
    return (
        <div>
            <Skeleton width="10%" className="mb-2"></Skeleton>
            <Skeleton width="20%" className="mb-2"></Skeleton>
            <br />
            <ul className="grid grid-cols-5 gap-4">
                {Array.from({ length: 5 }).map((_, i) => (
                    <li key={i | _}>
                        <Skeleton className="mb-2"></Skeleton>
                    </li>
                ))}
            </ul>
            <ul>
                {Array.from({ length: 10 }).map((_, i) => (
                    <li>
                        <ul className="grid grid-cols-5 gap-4 items-center">
                            <li className="mb-2">
                                <div className="flex items-center">
                                    <Skeleton
                                        shape="circle"
                                        size="4rem"
                                        className="mr-2"
                                    ></Skeleton>
                                    <div style={{ flex: "1" }}>
                                        <Skeleton width="100%" className="mb-2"></Skeleton>
                                        <Skeleton width="75%"></Skeleton>
                                    </div>
                                </div>
                            </li>
                            {Array.from({ length: 4 }).map((_, i) => (
                                <li key={i | _}>
                                    <Skeleton className="mb-2"></Skeleton>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    )
}
export default AddProductsLoader