import React from "react";
import useData from "../../hooks/useData";
import Product from "../../Components/CategoryWiseProduct/Product";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import Loader from "../../Components/CategoryWiseProduct/Loader";
import Error from "../../Components/Error/Error";
import PageHeading from "../../Components/PageHeading/PageHeading";

const AllProductContainer = () => {
  const { update,setUpdate,profile } = useData();
  const navigate = useNavigate()
  const {data,error,loading} = useFetch("api/product?page=0&size=100&sort=-created_at",[update])

  if (profile?.role_type?.name==="Admin") {
    navigate("/")
    return null
  }


  return (
    <div className="raleway mt-14 p-4">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <PageHeading title={'All Product List'} sub={'A list of the products you can buy from the store'}/>
        {
            !error ? <>
                {
                    !loading ? <div className="grid grid-cols-2 lg:grid-cols-7 gap-2">
                        {data?.items?.length > 0 &&
                            data?.items?.map(({ name, id, price, quantity, imageURL }, j) => (
                                    <Product
                                        key={j}
                                        name={name}
                                        price={price}
                                        quantity={quantity}
                                        imageURL={imageURL}
                                        id={id}
                                        update={update}
                                        setUpdate={setUpdate}
                                    />
                            ))}
                    </div> : <Loader/>
                }
            </> : <Error error={error}/>
        }
    </div>
  );
};

export default AllProductContainer;
