import {useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate()
    return <div className="h-screen w-full flex items-center justify-center">
        <div className={'text-center'}>
            <img src="https://i.postimg.cc/2yrFyxKv/giphy.gif" alt="gif_ing"/>
            <h1 className={'text-7xl font-black text-red-500 mt-4'}>404</h1>
            <h1 className={'text-4xl font-bold'}>Page Not Found</h1>
            <button onClick={()=>navigate("/")} className={'px-2 py-1 bg-gray-200 text-sm mt-4'}>Back to Home</button>
        </div>
    </div>
}
export default NotFound