import React, {useState} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {errorNotify, successNotify} from "../../utils/helper";
import useData from "../../hooks/useData";
import ServiceRequestList from "./ServiceRequestList";
import PageHeading from "../../Components/PageHeading/PageHeading";
import {usePostData} from "../../hooks/usePostData";
import MButton from "../../Components/MButton/MButton";

const ServiceRequest = () => {
  const { update,setUpdate } = useData();
  const [data, setData] = useState({});
  const {submitData} = usePostData();
  //HANDLE INPUT STATE VALUE
  const handleInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  //ADD SERVICE REQUEST
  const addRequest = async (e) => {
    e.preventDefault();
    const postData = {
      title: data?.title,
      detail: data?.detail,
    };
    const response = await submitData(JSON.stringify(postData),'api/service_request');
    if (response?.status){
      successNotify("Service Request Placed Successfully!");
      setUpdate(!update)
    }else{
      errorNotify(response?.errors[0]);
    }
  };
  return (
    <div className="raleway mt-14">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 lg:col-span-9 order-2 lg:order-1">
        <div className="p-4">
          <ServiceRequestList />
        </div>
        </div>
        <div className="col-span-12 lg:col-span-3 order-1 lg:order-2">
          <div className="p-4 h-auto lg:h-[93vh] lg:border-l">
            <PageHeading title={'Service Request'} sub={'Request the service you need right away.'}/>
            <form onSubmit={addRequest} className="mt-3">
              <div className="mb-3">
                <label className={'text-sm text-gray-600'}>Service Title</label>
                <input
                  name="title"
                  onChange={handleInput}
                  type="text"
                  className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                  placeholder="Start from here"
                />
              </div>
              <div className="mb-4">
                <label className={'text-sm text-gray-600'}>Service that you require</label>
                <textarea className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                  value={data?.detail}
                  onChange={handleInput}
                  rows={4}
                  cols={50}
                  name="detail"
                  placeholder="Elaborate the service that you require..."
                />
              </div>
              <MButton type={'submit'} label={"Request"}/>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequest;
