import React from 'react'
import useData from '../hooks/useData';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../Components/Loader/Loader';

const AdminRoute = ({children}) => {
    const { profile,loader } = useData();
    const location = useLocation();
    const isAdmin = ["Admin","Super Admin"].includes(profile?.role_type?.name);
    if (loader) {
      return <Loader/>
    }
    if (profile?.role_type?.name && (!isAdmin)) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
  };

export default AdminRoute