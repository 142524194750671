import React, {useEffect, useState} from "react";
import {BsBell} from "react-icons/bs";
import {HiBars3} from "react-icons/hi2";
import {RxCross1} from "react-icons/rx";
import {baseUrl, errorNotify, formatTimeAgo,} from "../../utils/helper";
import "./Header.css";
import useData from "../../hooks/useData";
import {Link, useNavigate} from "react-router-dom";
import "rodal/lib/rodal.css";
import "react-toastify/dist/ReactToastify.css";
import {Dialog} from "primereact/dialog";

const Header = ({show, setShow}) => {
    const {token, profile, setProfile, setToken,profileUpdater,toggleDrawer,isOpen} = useData();
    const [notification, setNotification] = useState(null);
    const [notificationSeen, setNotificationSeen] = useState(false);
    const [unSeenNotification, setUnSeenNotification] = useState(null);
    const image = require("./rasel.png");
    const navigate = useNavigate();
    const [fromDate, setFormDate] = useState();
    const [data, setData] = useState({});
    const [toDate, setToDate] = useState();
    let date = new Date();
    const [reports,setReports] = useState({})
    const [showContent, setShowContent] = useState(false);
    const adminPermission = ["Admin", "Super Admin"].includes(profile?.role_type?.name);
    useEffect(() => {
        const targetDate = new Date(2024, 0, 21);

        const checkDate = () => {
            const today = new Date();
            const isSameDay = today.getFullYear() === targetDate.getFullYear() &&
                today.getMonth() === targetDate.getMonth() &&
                today.getDate() === targetDate.getDate();

            setShowContent(isSameDay);
        };
        checkDate();
        // const interval = setInterval(checkDate, 60000);
        // return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const getProfile = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/api/profile`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setProfile(resData.data);
                }
            } catch (error) {
                errorNotify(error.message);
            }
        };
        getProfile().then();
    }, [token, setProfile,profileUpdater]);
    useEffect(() => {
        const getNotificationFrormRoute = async () => {
            const res = await fetch(
                `${baseUrl.url}/api/notification?page=0&size=100&sort=-id`,
                {
                    method: "Get",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                }
            );
            if (res.ok && res.status !== 204) {
                const resData = await res.json();
                setNotification(resData.data?.items);
                const unseenNotification = resData?.data?.items?.filter(
                    (filterData) => filterData.is_seen === 0
                );
                setUnSeenNotification(unseenNotification);
            }
        };
        getNotificationFrormRoute().then();
    }, [token, notificationSeen]);
    const seeAllNotfi = async () => {
        const res = await fetch(`${baseUrl.url}/api/notification/make_seen`, {
            method: "Get",
            headers: {
                Authorization: `bearer ${token}`,
            },
        });
        if (res.status) {
            setNotificationSeen(!notificationSeen);
        }
    };
    const logout = async () => {
        try {
            const res = await fetch(`${baseUrl.url}/api/logout`, {
                method: "GET",
                credentials: "include",
                headers: {
                    Authorization: `bearer ${token}`,
                },
            });
            if (res.ok) {
                navigate("/login");
            } else {
                alert("Something went wrong");
            }
            setToken(null);
        } catch (error) {
            console.log(error);
        }finally {
            toggleDrawer()
        }
    };
    useEffect(() => {
        if (!adminPermission) {
            return;
        }
        const postData = {
            from: data?.from || "",
            to: data?.to || "",
        };
        const getReports = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/api/sales`, {
                    method: "PUT",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                    body: JSON.stringify(postData),
                });
                const resData = await res.json();
                if (res.ok) {
                    setReports(resData.data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getReports().then();
    }, [token, data, adminPermission]);


    return (
        <div className="py-2 px-4 fixed w-full top-0 left-0 z-50 backdrop-blur-md bg-white/20 border-b">
            <Dialog header="Happy Birthday Raktim" visible={showContent} style={{ width: window.innerWidth>700 ? '30vw' : '96%' }} onHide={() => setShowContent(false)}>
                <img src={'https://i.ibb.co/Bgb5BBV/Raktim-canva.png'} alt={'Raktim birthday'} className={'w-full h-full'}/>
            </Dialog>
            <div className="grid grid-cols-12 items-center">
                <div className="col-span-6 lg:col-span-2 flex items-center">
                    <div className="h-8 w-8 flex items-center justify-center">
                        <HiBars3
                            onClick={() => setShow(!show)}
                            size={30}
                            className={`cursor-pointer ${show ? 'text-gray-900' : 'text-indigo-900'}`}
                        />
                    </div>
                    <Link to="/">
                        <img src={image} alt="" className="h-10 ml-4"/>
                    </Link>
                </div>
                <div className="hidden lg:block col-span-8 lg:col-span-8"></div>
                <div className="col-span-6 lg:col-span-2 flex items-center justify-end">
                    <div className="mr-4">
                        <div className=" relative group">
                            <div title="Notifications">
                                <BsBell
                                    size={20}
                                    className={`cursor-pointer -mb-1 relative ${
                                        unSeenNotification?.length > 0
                                            ? "text-blue-500"
                                            : "text-gray-700"
                                    }`}
                                />
                                <p className="absolute -top-3 left-3 w-4 h-4 rounded-full bg-blue-100  flex items-center text-xs justify-center text-blue-500">
                                    {unSeenNotification?.length>0 ? unSeenNotification?.length : 0}
                                </p>
                            </div>
                            <div
                                className="shadow-2xl bg-white text-slate-800 rounded-2xl overflow-hidden z-50 w-80 absolute top-full md:right-0 -right-[64px] hidden group-hover:block">
                                <div className="flex items-center justify-between p-2 border-b bg-blue-500 text-white">
                                    <h1 className="text-lg font-bold dark:text-yellow-500">
                                        Notification
                                    </h1>
                                    <button
                                        onClick={seeAllNotfi}
                                        className="text-white text-sm"
                                    >
                                        Mark all as read
                                    </button>
                                </div>
                                {notification?.length > 0 ? (
                                    <div className="max-h-[350px] overflow-auto scrollBar shadow-lg">
                                        {notification?.map((notifi, i) => {
                                            const time = formatTimeAgo(notifi?.created_at);
                                            return (
                                                <div key={i}>
                                                    <div
                                                        className={`${
                                                            notifi?.is_seen === 0 && "bg-orange-50"
                                                        } p-2 grid grid-cols-12 items-start py-2  border-b`}
                                                    >
                                                        <div className="col-span-2">
                                                            <div
                                                                className={`shadow h-10 w-10 flex items-center justify-center rounded-full p-[2px] relative bg-blue-400`}
                                                            >
                                                                <div
                                                                    className={`h-8 w-8 rounded-full flex items-center justify-center bg-blue-500 text-white`}
                                                                >
                                                                    <h3 className="text-xs">ME</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-10">
                                                            <p className="font-medium text-blue-500">
                                                                {notifi?.title}
                                                            </p>
                                                            <p className="pt-1 text-sm">{notifi.message}</p>
                                                            <div className="flex items-center">
                                <span className="text-xs text-gray-500">
                                  {time}
                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="relative h-40 bg-white">
                                        <div className="absolute z-50 w-full h-full p-4">
                                            <h1 className="font-bold text-slate-700">
                                                No notification
                                            </h1>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex items-center cursor-pointer gap-1"
                        onClick={()=>{
                            navigate("/profile")
                            setTimeout(()=>{
                                toggleDrawer()

                            },200)
                        }}
                    >
                        <img
                            src={`${baseUrl.url}${profile?.imageURL}`}
                            alt="profile"
                            className="h-8 w-8 object-cover rounded-full"
                        />
                        <div className="ml-2 hidden lg:block">
                            <h1 className={'text-sm underline'}>{profile?.name}</h1>
                            <p className="text-xs -mt-[2px] text-gray-700">
                                {profile.role_type?.name}
                            </p>
                        </div>

                    </div>
                    {isOpen && <div onClick={toggleDrawer} className={'fixed top-0 right-0 h-screen w-full bg-gray-950 opacity-40'}></div>}
                    <div
                        className={`bg-gray-100 fixed top-0 right-0 w-80 shadow-lg transition-transform transform h-[100vh] ${
                            isOpen ? "translate-x-0" : "translate-x-full"
                        }`}
                    >

                        <div>
                            <div className=" flex items-center justify-between">
                                <div className="flex items-center p-2 rounded">
                                    <div className="h-10 w-10 border rounded-full overflow-hidden">
                                        <img
                                            src={`${baseUrl.url}${profile.imageURL}`}
                                            alt=""
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <div className="ml-2 text-gray-800">
                                        <h1>{profile?.name}</h1>
                                        <p className="text-xs -mt-1 text-blue-500">
                                            {profile.role_type?.name}
                                        </p>
                                    </div>
                                </div>

                                <RxCross1
                                    onClick={toggleDrawer}
                                    size={20}
                                    className="cursor-pointer absolute top-2 right-2 text-blue-500"
                                />
                            </div>
                            {adminPermission && <div style={{background:'#132d7f'}} className='w-[96%] mx-auto rounded-xl'>
                                <div className="p-4 pb-0 font-semibold text-gray-50">
                                    Search Your Revenue
                                </div>

                                <div className="grid grid-cols-12 gap-2 px-4 ">
                                    <div className="col-span-12">
                                        <label
                                            className={'text-sm text-gray-100'}>From</label>
                                        <input
                                            className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm text-white"
                                            type="date"
                                            defaultValue={date.toDateString().substring(0, 10)}
                                            onChange={(e) => setFormDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <label
                                            className={'text-sm text-gray-100'}>To</label>
                                        <input
                                            className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm text-white"
                                            type="date"
                                            defaultValue={date.toDateString().substring(0, 10)}
                                            onChange={(e) => setToDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-span-12">
                                        <button
                                            className="bg-blue-500 rounded-xl p-1 w-full text-white text-center"
                                            onClick={() => {
                                                if (fromDate && toDate) {
                                                    setData({...data, from: fromDate, to: toDate});
                                                }
                                            }}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="p-4">
                                    <h1 className="text-sm text-gray-100">
                                        Total Sold Products :{" "}
                                        <span className="text-gray-100 font-bold">
                                      {reports?.no_of_sold_product}
                                    </span>
                                    </h1>
                                    <h1 className="text-sm text-gray-100">
                                        Total Revenue:{" "}
                                        <span className="text-gray-100 font-bold">
                                      {reports?.total_amount}
                                    </span>{" "}
                                        ৳
                                    </h1>
                                </div>
                            </div>}
                            <div className='flex justify-center'>
                                <button
                                    onClick={logout}
                                    className="py-2 px-3 bg-blue-500 rounded-xl w-[96%] text-white mt-2"
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
