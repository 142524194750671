import React, {useState} from "react";
import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import useData from "../../hooks/useData";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BsEye, BsEyeSlash} from "react-icons/bs";
import './Login.css'
const Login = () => {
  const { setLogin, setToken } = useData();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const [loginLoader,setLoginLoader] = useState(false);
  const handleInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    setLoginLoader(true)
    e.preventDefault();
    if (!data?.username || data?.username?.length < 5) {
      errorNotify("Email required");
      return;
    }
    if (!data?.password || data?.password?.length < 5) {
      errorNotify("Minimum 6 digit required");
      return;
    }
    try {
      const res = await fetch(`${baseUrl.url}/auth/authenticate`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
      });
      const resData = await res.json();
      if (res.ok) {
        setToken(resData.access_token);
        successNotify("Login Successfully");
        navigate("/");
      }else{
        errorNotify(resData.errors[0])
      }
      setLogin(true);
    } catch (error) {
      console.log(error)
    }finally{
      setLoginLoader(false)
    }
  };

  const loader = <img src="https://i.gifer.com/ZKZg.gif" className="w-5 h-5 ml-2" alt="loader"/>
  const bgs = ['banner3.jpg','banner4.jpg']
  return (
    <div className="w-full h-screen relative z-10 flex items-center justify-center bg-gray-100">
      {/*<div className='sun bg-white h-20 w-20 absolute z-50 rounded-full'></div>*/}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div
        className="absolute block h-full w-full top-0 left-0 z-20"
      >
        <img
          src={require(`../../assets/${bgs[1]}`)}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <form
        onSubmit={handleLogin}
        className="w-[96%] lg:w-[480px] relative z-50 bg-white shadow p-4 backdrop-blur-md bg-white/30 rounded-xl"
      >
        <div className="flex items-center justify-center">
          <img src={require("./rasel.png")} alt="" className="w-40 h-12" />
        </div>
        <div className="mb-4 mt-8">
          <label htmlFor="username" className="text-gray-600">Username</label>
          <input
            onChange={handleInput}
            name="username"
            placeholder="username"
            type="text"
            className="w-full p-2 focus:outline-none rounded-xl block border bg-transparent border-white"
          />
        </div>
        <div className="mb-2">
          <label htmlFor="username" className="text-gray-600">Password</label>
          <div className="relative">
            <input
              onChange={handleInput}
              name="password"
              placeholder="password"
              type={!view ? "password" : "text"}
              className="w-full p-2 focus:outline-none rounded-xl block border bg-transparent border-white"
            />
            <div className="absolute top-3 right-4" onClick={()=>setView(!view)}>
              {!view ? <BsEyeSlash /> :<BsEye />}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="w-full p-2 focus:outline-none rounded-xl block bg-indigo-500 hover:bg-indigo-600 transition-all duration-300 text-white mt-4 cursor-pointer flex items-center justify-center"
        >Login {loginLoader && loader}</button>
        <p className="text-sm text-center mt-2">Selopia &copy; 2023</p>
      </form>

    </div>
  );
};

export default Login;
