import React, {useState} from "react";
import Rating from "react-rating";
import {reviewsCategory} from "../../utils/data";
import {errorNotify, successNotify} from "../../utils/helper";
import useData from "../../hooks/useData";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {BsPatchCheckFill} from "react-icons/bs";
import PageHeading from "../../Components/PageHeading/PageHeading";
import {usePostData} from "../../hooks/usePostData";
import MButton from "../../Components/MButton/MButton";

const AddReview = () => {
  const { profile,setProfile } = useData();
  const [ratings, setRatings] = useState(Array(reviewsCategory.length).fill(0));
  const [remarks, setRemarks] = useState("");
  const {submitData} = usePostData();
  const handleRatingChange = (index, rate, e) => {
    const updatedRatings = [...ratings];
    updatedRatings[index] = rate;
    setRatings(updatedRatings);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      office_assistance: ratings[0],
      food_and_beverage: ratings[1],
      office_cleanliness: ratings[2],
      restroom_cleanliness: ratings[3],
      kitchen_cleanliness: ratings[4],
      balcony_cleanliness: ratings[5],
      air_conditioner_and_lighting: ratings[6],
      plant_care: ratings[7],
      pest_control: ratings[8],
      behavior_and_attitude: ratings[9],
      service_promptness: ratings[10],
      in_house_store_service: ratings[11],
      optional_remarks: remarks,
    };
    const response = await submitData(JSON.stringify(postData),`api/rating`);
    if (response?.status){
          successNotify("Your rating placed successfully");
          setRatings([]);
          setRemarks("");
          setProfile({...profile,is_reviewed : true})
    }else{
      errorNotify(response?.errors[0]);
    }
  };

  if (profile?.is_reviewed === true) {
    return (
      <div className="h-[92vh] w-full flex items-center justify-center">
        <div className="text-center">
          <div className="flex items-center justify-center mb-2">
            <BsPatchCheckFill size={50} className="text-green-500" />
          </div>
          <h1 className="text-base lg:text-xl">You have already given the review</h1>
          <p className="text-sm lg:text-base text-gray-700">Try next week again</p>
        </div>
      </div>
    );
  }

  return (
    <div className="raleway mt-14 p-4">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <PageHeading title={'Add Review'} sub={'Based on your experience from last week, provide a review for Rasel.'}/>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
          {reviewsCategory.map(({ icon, text }, i) => {
            return (
              <div key={i} className="bg-gray-100 p-2 rounded ">
                <div className="">
                  <div className="w-20 h-20 flex items-center justify-center rounded-b-full bg-gray-200 -mt-2">
                    {icon}
                  </div>
                  <label htmlFor="" className="flex items-center text-xl">
                    <span className="text-gray-700">{text}</span>
                  </label>
                </div>
                <Rating
                  onChange={(rate) => handleRatingChange(i, rate)}
                  initialRating={ratings[i]}
                  emptySymbol={
                    <img
                      alt=""
                      style={{ height: "35px" }}
                      src={require("../../assets/icons/grey-star.png")}
                      className="icon"
                    />
                  }
                  placeholderSymbol={
                    <img
                      alt=""
                      style={{ height: "35px" }}
                      src={require("../../assets/icons/yellow-star.jpg")}
                      className="icon"
                    />
                  }
                  fullSymbol={
                    <img
                      alt=""
                      style={{ height: "35px" }}
                      src={require("../../assets/icons/yellow-star.jpg")}
                      className="icon"
                    />
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="mt-4">
          <textarea
            name=""
            id=""
            rows="2"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Write something thoughtful..."
            className="focus:outline-none border rounded block w-full lg:w-[420px] p-4"
          ></textarea>
        </div>
        <div className="pb-4 mt-4">
          <MButton type={'submit'} label={"Submit"}/>
        </div>
      </form>
    </div>
  );
};

export default AddReview;
