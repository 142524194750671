import {baseUrl} from "../utils/helper";
import useData from "./useData";

export const usePostData = () => {
    const {token} = useData();

    const submitData = async (data, endpoint,method="POST") => {
        try {
            const res = await fetch(`${baseUrl.url}/${endpoint}`,
                {
                    method: method,
                    // credentials: "include",
                    headers: {
                        Authorization: `bearer ${token}`,
                    },
                    body: data,
                }
            );
            return  await res.json();
        } catch (err) {
            return {
                errors:[err.message]
            }
        }
    }


    return {
        submitData
    }
}