import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_DOMAIN}),
    tagTypes:['Products'],
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (arg) => ({
                url:arg.url,
                method: "GET",
                credentials: "include",
                headers: {
                    "Authorization": `bearer ${arg.token}`
                }
            }),
            providesTags:['Products']
        }),
        addNewProduct:builder.mutation({
                query:(arg) =>(
                    {
                        url:arg.url,
                        method: "POST",
                        credentials: "include",
                        headers: {
                            "Authorization": `bearer ${arg.token}`
                        },
                        body:arg.data
                    }
                ),
                invalidatesTags:['Products']
            }
        ),
        updateProduct:builder.mutation({
            query:(arg)=>(
                {
                    url:arg.url,
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Authorization": `bearer ${arg.token}`
                    },
                    body:arg.data
                }
            ),
            invalidatesTags:['Products']
        }),
        deleteProduct:builder.mutation({
            query:(arg)=> ({
                url:arg.url,
                method:'DELETE',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `bearer ${arg.token}`
                },
                body:arg.data
            }),
            invalidatesTags:['Products']
        })
    }),
})
export const {useGetProductsQuery,useAddNewProductMutation,useDeleteProductMutation,useUpdateProductMutation} = productsApi