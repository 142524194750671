import React from 'react';

const Overlay = ({setShow}) => {
    return (
        <div
            onClick={() => setShow(false)}
            className="block fixed z-30 top-0 left-0 h-screen w-full bg-black opacity-10 lg:hidden"
        ></div>
    );
};

export default Overlay;