import React, { createContext } from "react";
import useStore from "../hooks/useStore";
export const AllContext = createContext();
const ContextProvider = ({ children }) => {
  const data = useStore()
  return (
    <AllContext.Provider value={data}>{children}</AllContext.Provider>
  );
};

export default ContextProvider;
