import React from "react";
import "./Banner.css";
import Marquee from "react-fast-marquee";
import {useGetProductsQuery} from "../../rtk/api/productsApi";
import useData from "../../hooks/useData";
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import BuyerCard from "./BuyerCard/BuyerCard";

const Banner = () => {
    const {token} = useData();
    const {data,isLoading:loading,error} = useGetProductsQuery({url:'api/best_buyers', token:token});


    return (
        <div>
            <div className='w-full'>
               <img alt={'banner'} className='w-full' src={require('../../assets/banner2.png')}/>
            </div>
            <div className="w-full -mt-12 h-full top-0 left-0 flex items-center justify-center z-20 p-4">
                {
                    !error ? <>
                        {
                            !loading ? <div className=" relative w-full">
                                <Marquee>
                                    {data?.data?.map((buyer, i) => (
                                        <BuyerCard index={i} buyer={buyer}/>
                                    ))}
                                </Marquee>
                            </div> : <div className="w-full h-28 rounded-xl bg-gray-300"></div>
                        }</> : <div>
                        <h1>Something went wrong</h1>
                        <p>{error}</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default Banner;
