import React, {useState} from "react";
import {baseUrl, formatTimeAgo} from "../../utils/helper";
import useData from "../../hooks/useData";
import {BsEmojiSmile} from "react-icons/bs";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import RaselInfo from "./RaselInfo";
import {ReviewNavigation} from "./ReviewNavigation";
import Design from "./Design";
import {useFetch} from "../../hooks/useFetch";
import Error from "../Error/Error";
import {Skeleton} from "primereact/skeleton";
import Navigations from "./Navigations";
import Revenue from "./Revenue";
import Overlay from "./Overlay";

const Sidebar = ({show, setShow}) => {
    const {token, profile,update,setUpdate,toggleDrawer} = useData();
    const [dueModal, setDueModal] = useState(false);
    const {data:dueData,error:dueErr,loading:dueLoading} = useFetch('api/product_purchase/due',[update]);
    const totalDue = dueData?.reduce(
        (acc, current) => acc + current.due_amount,
        0
    );
    const changePaidStatus = async (data) =>{
        const res = await fetch(`${baseUrl.url}/api/product_purchase/make_paid/${data?.user_id}`,{
            method: "GET",
            credentials: "include",
            headers: {
                "Authorization": `bearer ${token}`
            }
        })
        if(res.ok){
            setUpdate(!update)
        }
    }
    //SALES REPORTS





    const dueName = (data) => {
        return <div>
            <h4>{data.name}</h4>
            <p className="text-xs -mt-1">{formatTimeAgo(data?.last_paid_on)}</p>
        </div>
    };
    const dueAmount = (data) => {
        return data.due_amount;
    };
    const paidOption = (data)=>{
        return <button className={`bg-green-500 text-white text-xs py-1 px-2`} onClick={()=>changePaidStatus(data)}>Paid</button>
    }
    const adminPermission = ["Admin", "Super Admin"].includes(profile?.role_type?.name);
    return (
        <aside>
            <Rodal
                visible={dueModal}
                onClose={() => {
                    setDueModal(!dueModal);
                }}
                animation={"zoom"}
                customStyles={{
                    minWidth: "350px",
                    height: "450px",
                    width: "15%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                    overflow: "scroll",
                }}
            >
                {
                    !dueErr ? <>
                        {
                            !dueLoading ? <>
                                {dueData?.length > 0 ? (
                                    <div className="p-2">
                                        <DataTable
                                            size="small"
                                            value={dueData}
                                            tableStyle={{minWidth: "auto"}}
                                        >
                                            <Column header="Name/Last Paid On" body={dueName}></Column>
                                            <Column header="Due" body={dueAmount}></Column>
                                            <Column header="Action" body={paidOption}></Column>
                                        </DataTable>
                                        <div className="flex justify-between items-center px-1 py-2">
                                            <h4>Total Due</h4>
                                            <p className="pr-6">{totalDue}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center h-full">
                                        <div className="text-center">
                                            <div className="flex items-center justify-center">
                                                <BsEmojiSmile size={40} className="text-center text-gray-500"/>
                                            </div>
                                            <h1 className="text-xl text-gray-700">No data found</h1>
                                            <p className="text-sm">It seems like no date here</p>
                                        </div>
                                    </div>
                                )}
                            </> : <>
                                <ul>
                                    {Array.from({ length: 20 }).map((_, i) => (
                                        <li key={i}>
                                            <ul className="grid grid-cols-2 gap-4 items-center">

                                                {Array.from({ length: 2 }).map((_, i) => (
                                                    <li key={i | _}>
                                                        <Skeleton className="mb-2"></Skeleton>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        }
                    </> : <Error error={dueErr}/>
                }

            </Rodal>

            {/*OVERLAY SECTION START FROM HERE*/}

            {show && (
                <Overlay setShow={setShow}/>
            )}
            {/*OVERLAY SECTION END FROM HERE*/}

            <div
                className={`raleway w-72 h-screen overflow-auto fixed top-0 pt-16 transition-all duration-200 z-30 bg-white border-r ${
                    show ? "left-0" : "-left-full"
                } p-2 pb-0 z-40`}
            >
                <RaselInfo/>
                {!adminPermission && <ReviewNavigation show={show} setShow={setShow}/>}

                {adminPermission && (
                    <Revenue toggleDrawer={toggleDrawer} setDueModal={setDueModal}/>
                )}
                <Navigations setShow={setShow} profile={profile}/>
                <Design/>
            </div>
        </aside>
    );
};

export default Sidebar;

