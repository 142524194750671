import {ProductLoader} from "./ProductLoader";

const Loader = ()=>{
    return (
        <div className="grid grid-cols-2 lg:grid-cols-7 gap-2">
            {
                Array.from({length:30}).map((_,i)=><ProductLoader key={i}/>)
            }

        </div>
    )
}
export default Loader
