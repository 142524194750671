import React, {useEffect, useState} from "react";
import {baseUrl, errorNotify, formatTimeAgo, successNotify,} from "../../utils/helper";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import useData from "../../hooks/useData";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Empty from "../../Components/Empty/Empty";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import OrderListLoader from "./OrderListLoader";
import Error from "../../Components/Error/Error"
import PageHeading from "../../Components/PageHeading/PageHeading";
import {usePostData} from "../../hooks/usePostData";
import {Paginator} from "primereact/paginator";
import { RxCross1 } from "react-icons/rx";
// import {useGetOrdersQuery} from "../../rtk/api/ordersApi";

const OrderList = () => {
    // LOCAL AND GLOBAL STATES DECLARATION
    const {update, setUpdate, profile,token} = useData();
    const [searchText, setSearchText] = useState("");
    const [finalText, setFinalText] = useState("")
    const {submitData} = usePostData();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(11);
    const adminPermission = ["Admin","Super Admin"].includes(profile?.role_type?.name)
    let pageNum = ((first / rows));
    // const {data,isLoading:loading,error} = useGetOrdersQuery({url:`api/product_purchase?page=${pageNum}&size=${rows}&sort=-created_at&filters=[["ordered_by.name","like","${finalText}"]]`,token:token})
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null)
    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    const handleChangeStatus = async (id, statusId) => {
        let postData = {};
        postData = {
            status: Number(statusId),
        };
        // changeStatus({url:`api/product_purchase/${id}`,data:JSON.stringify(postData),token:token});
        // if (isSuccess){
        //     successNotify("Product order status updated successfully!");
        //     setTimeout(() => {
        //         setUpdate(!update);
        //     }, 400);
        // }
        const response = await submitData(JSON.stringify(postData), `api/product_purchase/${id}`, "PUT");
        if (response?.status) {
            successNotify("Product order status updated successfully!");
            setTimeout(() => {
                setUpdate(!update);
            }, 400);
            const newOrders = data?.items?.map((order)=>{
                if (order.id===Number(id)){
                    return {
                        ...order,
                        status:Number(statusId)
                    }
                }
                return order
            })
            let newData = {
                items:newOrders,
                last_page:data?.last_page
            }
            setData(newData)
        } else {
            errorNotify(response?.errors[0]);
        }
    };
    const productName = (data) => {
        return (<div className="flex items-center gap-1">
            <div className="">
                <img
                    src={`${baseUrl?.url}${data?.product?.imageURL}`}
                    className=" w-10 h-10 rounded-full"
                    alt=""
                />
            </div>
            <div>
                <span className="whitespace-nowrap block">{data?.product?.name}</span>
                <p className="whitespace-nowrap text-xs -mt-1">
                    {formatTimeAgo(data?.created_at)}
                </p>
            </div>
        </div>);
    };
    const orderBy = (data) => {
        return (<div className="flex items-center">
            <div className="w-8 h-8 rounded overflow-hidden">
                <img
                    src={`${baseUrl.url}${data?.ordered_by?.imageURL}`}
                    alt=""
                    className="w-full h-full object-cover"
                />
            </div>
            <span className="whitespace-nowrap ml-2">{data?.ordered_by?.name}</span>
        </div>);
    };
    const quantityPrice = (data) => {
        return `${data?.quantity}X ${data?.unit_price}`;
    };
    const total = (data) => {
        return <span>{data?.total_price}</span>;
    };

    let statuses = [{name: "Ordered", code: 0}, {name: "Confirmed", code: 1}, {
        name: "Delivered",
        code: 2
    }, {name: "Paid", code: 3}, {name: "Cancelled", code: 9},];

    let newStatuses;
    const statusTemplate = (data) => {
        const cStatus = data?.status;
        const id = data?.id;
        if (cStatus === 0) {
            newStatuses = [...statuses];
        } else if (cStatus === 1) {
            newStatuses = statuses?.filter((status) => status?.code !== 0 && status);
        } else if (cStatus === 2) {
            newStatuses = statuses?.filter((status) => status?.code === 2 || status?.code === 3);
        }
        const adminPermission = ["Admin","Super Admin"].includes(profile?.role_type?.name)

        return (<div>
            {cStatus !== 3 && cStatus !== 9 ? (adminPermission ? (<select
                value={cStatus}
                onChange={(e) => handleChangeStatus(id, e.target.value)}
                className="bg-white focus:outline-none"
            >
                {newStatuses?.map((status, i) => (<option key={i} value={status?.code} className="">
                    {status?.name}
                </option>))}
            </select>) : (<>
                {cStatus === 0 ? (<select
                    onChange={(e) => handleChangeStatus(id, e.target.value)}
                    className="bg-white p-1 focus:p-1 focus:outline-none"
                >
                    <option>Ordered</option>
                    <option value="9">Cancelled</option>
                </select>) : cStatus === 1 ? ("Confirmed") : ("Delivered")}
            </>)) : (<>
                {cStatus === 3 ? (<span className="p-1">Paid</span>) : (<span className="p-1">Cancelled</span>)}
            </>)}
        </div>);
    };
    useEffect(() => {
        setLoading(true)
        const getAllOrders = async ()=>{
            try {
                const res = await fetch(`${baseUrl.url}/api/product_purchase?page=${pageNum}&size=${rows}&sort=-created_at&filters=[["ordered_by.name","like","${finalText}"]]`,{
                    method:"GET",
                    credentials:"include",
                    headers:{
                        "Authorization":`bearer ${token}`
                    }
                })
                const resData = await res.json();
                if (res.ok){
                    setData(resData.data)
                }
            }catch (e) {
                setError(e.message)
            }finally {
                setLoading(false)
            }
        }
        getAllOrders().then()
    }, [finalText,pageNum,rows,token]);

    return (<div className="raleway p-4 mt-14">
        {!error ? <>
            {!loading ? <>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <PageHeading title={'Product Order List'} sub={'A list of the ordered products and their status.'}/>
                {(profile?.role_type?.name === "Admin" || profile?.role_type?.name === "Super Admin") && <div className="mb-2 flex items-center">
                    <span className="p-input-icon-right mr-2">
                        {searchText?.length > 0 && <RxCross1 className={'cursor-pointer'} onClick={() => {
                            setFinalText("")
                            setSearchText("")
                        }}/>}
                      <InputText
                          onChange={(e) => {
                              setSearchText(e.target.value)
                          }}
                          value={searchText}
                          className="p-inputtext-sm"
                          placeholder="Search"
                      />
                    </span>
                    <span className={'mr-2'}>
                        <Button
                            style={{background:'dodgerblue',border:'none'}}
                            onClick={() => setFinalText(searchText)}
                            size="small"
                            label="Search"
                        />
                    </span>
                </div>}
                {data?.items?.length > 0 ?
                    <div>
                        <DataTable
                            value={data?.items}
                            size={"small"}
                            tableStyle={{minWidth: "50rem"}}
                            selectionMode="single"
                        >
                            <Column body={productName} header="Product"></Column>
                            {adminPermission && (
                                <Column body={orderBy} header="OrderedBy"></Column>)}
                            <Column body={quantityPrice} header="QtyXPrice"></Column>
                            <Column body={total} header="TotalPrice"></Column>
                            <Column body={statusTemplate} header="Status"></Column>
                        </DataTable>
                        <Paginator first={first} rows={rows} totalRecords={data?.last_page * rows}
                                   rowsPerPageOptions={[11, 22, 33]} onPageChange={onPageChange}/>
                    </div>
                    : <Empty/>}
            </> : <OrderListLoader/>}
        </> : <Error error={error}/>}
    </div>);
};

export default OrderList;