import useData from "../../hooks/useData";
import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Loader/Loader";
import Empty from "../../Components/Empty/Empty";
import {useFetch} from "../../hooks/useFetch";
import AddProductsLoader from "../AddProduct/AddProductsLoader";
import Error from "../../Components/Error/Error"
import PageHeading from "../../Components/PageHeading/PageHeading";
const ServiceRequestList = () => {
  const { token, update, setUpdate,profile } = useData();
  //GET SERVICE REQUEST ALL
  const {data,loading,error} = useFetch("api/service_request?page=0&size=100&sort=-created_at")
  //DROPDOWN MENU TOGGLE

  //HANDLE STATUS CHANGE
  const handleStatus = async (id, status) => {
    const postData = {
      is_accepted_by_admin: Number(status),
    };

    try {
      const res = await fetch(`${baseUrl.url}/api/service_request/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      const resData = await res.json();
      if (resData.status) {
        successNotify("Service Request Status Updated Successfully!");
        setUpdate(!update);
      } else {
        errorNotify(resData?.errors[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //prime react
  const statuses = [
    { name: "Approved", code: 1 },
    { name: "Delivered", code: 2 },
    { name: "Rejected", code: 9 },
  ];
  const NameTemplate = (data) => {
    const name = data?.requested_by?.name;
    return <span>{name}</span>;
  };

  const statusTemplate = (data) => {
    const status = data?.is_accepted_by_admin;
    const id = data?.id;
    return (
      <div>
        {(profile?.role_type?.name === "Admin" || profile?.role_type?.name === "Super Admin") ? (
          <>
            <div>
              <select
                defaultValue={status}
                onChange={(e) => handleStatus(id, e.target.value)}
                className="bg-white focus:p-1 focus:outline-slate-500 dark:bg-slate-800 cursor-pointer"
                disabled={status === 1}
              >
                {statuses?.map((status, i) => (
                  <option key={i} value={status?.code}>
                    {status?.name}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <div>
            <p
                className={`text-xs w-24 text-center p-[3px] px-[5px] text-white rounded-full text-white ${
                    status === 0
                        ? "bg-orange-400"
                        : status === 1
                            ? "bg-green-500"
                            : "bg-red-500"
                }`}
            >
              {status === 1
                ? "Approved"
                : status === 2
                ? "Delivered"
                :status === 9? "Rejected":'Not Approved'}
            </p>
          </div>
        )}
      </div>
    );
  };
  if (loading) {
    return <Loader/>;
    
  }
  return (
    <div className="raleway">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {
        !error ? <>
          {
            !loading ? <>
              <PageHeading title={'Service Request list'} sub={'A list of the requested services and their status.'}/>
              {data?.items?.length > 0 ? (
                  <div className="mt-3">
                    <DataTable
                        value={data?.items}
                        size={"small"}
                        dataKey="id"
                        tableStyle={{ minWidth: "50rem" }}
                        sortMode="multiple"
                        paginator
                        rows={17}
                        rowsPerPageOptions={[17, 5, 10, 25, 50]}
                    >
                      <Column header="Title" field="title" className="" />
                      <Column
                          header="Detail"
                          body={(d)=><p>{d.detail?.slice(0,50)}{d.detail?.length>50 ? '...' : ''}</p>}
                      />
                      {profile?.role_type?.name==="Admin" && <Column header="Requested By" body={NameTemplate} className="" />}
                      <Column header="Status" body={statusTemplate} className="" />

                    </DataTable>
                  </div>
              ) : (
                  <Empty/>
              )}
            </> : <AddProductsLoader/>
          }
        </> : <Error error={error}/>
      }
    </div>
  );
};

export default ServiceRequestList;
