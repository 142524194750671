import {IoFastFoodOutline, IoWaterOutline} from "react-icons/io5";
import {FiCoffee} from "react-icons/fi";
import {LuCandy, LuCookie, LuSofa} from "react-icons/lu";
import {GiBackpack, GiBoilingBubbles, GiCigarette, GiDragonfly} from "react-icons/gi";
import {CiBurger, CiStar} from "react-icons/ci";
import {BsClipboardCheck, BsShop, BsStars} from "react-icons/bs";
import {AiOutlinePlus, AiOutlineWindows} from "react-icons/ai";
import {MdOutlineHandshake} from "react-icons/md";
import {GoHome} from "react-icons/go";
import {RiCustomerService2Line, RiPlantLine} from "react-icons/ri";
import {TbAirConditioning} from "react-icons/tb";
import {BiCabinet, BiListOl} from "react-icons/bi";
import { FiUserPlus } from "react-icons/fi";

export const categories = [
  {
    icon: <GiCigarette size={30} />,
    name: "Cigaretes",
  },
  {
    icon: <FiCoffee size={30} />,
    name: "Coffee/Tea",
  },
  {
    icon: <LuCookie size={30} />,
    name: "Cookies",
  },
  {
    icon: <GiBackpack size={30} />,
    name: "Chips",
  },
  {
    icon: <LuCandy size={30} />,
    name: "Candy",
  },
  {
    icon: <CiBurger size={30} />,
    name: "Burger",
  },
  {
    icon: <IoWaterOutline size={30} />,
    name: "Water",
  },
];
export const reviewsCategory = [
  {
    icon: <BiCabinet size={50} />,
    text: "Office assistance",
  },
  {
    icon: <IoFastFoodOutline size={50} />,
    text: "Food & Beverage",
  },
  {
    icon: <BsStars size={50} />,
    text: "Office cleanliness",
  },
  {
    icon: <LuSofa size={50} />,
    text: "Restroom cleanliness",
  },
  {
    icon: <GiBoilingBubbles size={50} />,
    text: "Kitchen cleanliness",
  },
  {
    icon: <AiOutlineWindows size={50} />,
    text: "Balcony cleanliness",
  },
  {
    icon: <TbAirConditioning size={50} />,
    text: "Air Conditioner & Lighting",
  },
  {
    icon: <RiPlantLine size={50} />,
    text: "Plant care",
  },
  {
    icon: <GiDragonfly size={50} />,
    text: "Pest control",
  },
  {
    icon: <MdOutlineHandshake size={50} />,
    text: "Behaviour & Attitude",
  },
  {
    icon: <RiCustomerService2Line size={50} />,
    text: "Service promptness",
  },
  {
    icon: <BsShop size={50} />,
    text: "In-House store service",
  },
  
];
export const links = [
  { icon: <GoHome className='text-gray-800' size={20} />, text: "Home", path: "/" },
  {
    icon:<FiUserPlus className='text-gray-800' size={20}/>,
    text: "Add User",
    path: "add-user"
  },
  {
    icon: <BiListOl className='text-gray-800' size={25} />,
    text: "Order List",
    path: "/order-list",
  },
  {
    icon: <AiOutlinePlus className='text-gray-800' size={20} />,
    text: "Add Product",
    path: "/add-product",
  },

  {
    icon: <BsClipboardCheck className='text-gray-800' size={20} />,
    text: "Stock Request",
    path: "/stock-request",
  },

  {
    icon: <RiCustomerService2Line className='text-gray-800' size={20} />,
    text: "Service Request",
    path: "/service-request",
  },
  {
    icon: <CiStar className='text-gray-800' size={20} />,
    text: "Reviews",
    path: "/review-list",
  },
];
