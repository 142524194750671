import {ToastContainer} from "react-toastify";
import PageHeading from "../../Components/PageHeading/PageHeading";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Empty from "../../Components/Empty/Empty";
import AddProductsLoader from "../AddProduct/AddProductsLoader";
import Error from "../../Components/Error/Error";
import React, {useState} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import useData from "../../hooks/useData";
import {useAddUserMutation, useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation} from "../../rtk/api/usersApi";
import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import {FaEdit} from "react-icons/fa";
import { Checkbox } from 'primereact/checkbox';
import MButton from "../../Components/MButton/MButton";
import {ImSpinner2} from "react-icons/im";
const AddUser = () => {
    const {token} = useData()
    const [userData, setUserData] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedUserIds,setSelectedUserIds] = useState([])
    const {data,isLoading,error} = useGetUsersQuery({url:'/api/user?page=0&size=1000',token:token})
    const [addUser,{isLoading:addLoader}] = useAddUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [deleteUser] = useDeleteUserMutation()
    //INPUT STATE DATA ADD
    const handleCheckboxChange = (userId) => {
        const result = selectedUserIds.find(s=>s.id===userId);
        if (result) {
            setSelectedUserIds(
                selectedUserIds.filter((user) => user.id !== userId)
            );
        } else {
            setSelectedUserIds([...selectedUserIds, {id:userId}]);
        }
    };
    const handleInput = (e) => {
        setUserData({
            ...userData,
            [e.target.name]:
                e.target.type === "file" ? e.target.files[0] : e.target.value,
        });
    };
    const handleUser = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        for (const key in userData) {
            let element = userData[key];
            formData.append(`${key}`, element);
        }
        if(selectedUser.id){
            try {
                await updateUser({url:`/api/user/${selectedUser.id}`,token:token,data:formData}).unwrap();
                successNotify("User Updated Successfully");
                setSelectedUser({})
            }catch (e) {
                errorNotify(e.message)
            }
        }else{
            try {
                await addUser({url:'/api/register',token:token,data:formData}).unwrap();
                successNotify("User Added Successfully");
                setSelectedUser({})
            }catch (e) {
                errorNotify(e.message)
            }
        }
        e.target.reset();
    }

    const removeUser = async ()=>{
        const {error} = await deleteUser({data:JSON.stringify(selectedUserIds),url:'/api/user',token:token});
        if (!error){
            successNotify('Successfully User deleted');
            setSelectedUserIds([]);
            setUserData({})
        }
    }
    const user = (data)=>{
        return <div className={'flex items-center'}>
            <Checkbox onChange={() => handleCheckboxChange(data.id)} checked={selectedUserIds.some(user=>user.id===data.id)}></Checkbox>
            <div className={'h-8 w-8 overflow-hidden ml-1'}>
                <img alt={'profile'} className={'w-full h-full'} src={`${baseUrl.url}${data.imageURL}`}/>
            </div>
            <div className={'ml-2'}>
                <h1>{data.name}</h1>
                <p className={'text-xs -mt-1'}>{data.username}</p>
            </div>
        </div>
    }
    return (
        <div className="raleway mt-14">
            {addLoader && <div
                className='z-40 absolute top-0 left-0 h-full w-full flex bg-black opacity-10 items-center justify-center'>
                <div className='z-50 absolute top-0 left-0 h-full w-full flex items-center justify-center text-white'>
                    <ImSpinner2 className='spin360' size={35}/></div>
            </div>}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 lg:col-span-9 order-2 lg:order-1">
                    {
                        !error ? <>
                            {
                                !isLoading ? <div className='p-4'>
                                    <PageHeading title={'User List'} sub={'A list of the users of this store.'} btn={selectedUserIds.length>0 && 'Delete'} dltF={removeUser}/>
                                    {data?.data?.items?.length > 0 ? (
                                        <div className="mt-3">
                                            <DataTable
                                                value={data?.data?.items}
                                                size={"small"}
                                                dataKey="id"
                                                tableStyle={{ minWidth: "50rem" }}
                                                sortMode="multiple"
                                                paginator
                                                rows={13}
                                                rowsPerPageOptions={[13, 23, 33, 43, 53]}
                                            >
                                                <Column header="Name" body={user}  />
                                                <Column header="Phone" field="phone" />

                                                <Column
                                                    header="Role"
                                                    field={(data)=>data.role_type.name}
                                                />
                                                <Column header="Action" field={(data)=>{
                                                    return <div className="flex gap-1 items-center cursor-pointer">
                                                        <FaEdit
                                                            className="hover:text-blue-500"
                                                            onClick={() => {
                                                                setSelectedUser(data);
                                                            }}
                                                        />
                                                    </div>
                                                }} />
                                            </DataTable>
                                        </div>
                                    ) : (
                                        <Empty/>
                                    )}
                                </div> : <AddProductsLoader/>
                            }
                        </> : <Error error={error}/>
                    }
                </div>
                <div className="col-span-12 lg:col-span-3 order-1 lg:order-2">
                    <div className="p-4 lg:border-l h-auto lg:h-[92vh]">
                        <PageHeading title={'Add User'} sub={'Add a picture and list the user\'s specifications.'}/>
                        <form onSubmit={handleUser}>
                            <div className="mb-2 mt-4">
                                <div
                                    className="h-32 w-32 mx-auto border-dashed border-2 border-gray-400 bg-gray-50 relative flex items-center justify-center rounded">
                                    {(userData?.image_file || selectedUser?.imageURL) && (
                                        <img
                                            src={`${
                                                userData?.image_file
                                                    ? URL.createObjectURL(userData.image_file)
                                                    : `${baseUrl?.url}${selectedUser?.imageURL}`
                                            }`}
                                            className="absolute top-0 left-0 w-full h-full "
                                            alt=""
                                        />
                                    )}

                                    <input
                                        onChange={handleInput}
                                        type="file"
                                        name="image_file"
                                        className="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                                    />
                                    <AiOutlinePlus size={50} className="text-gray-400"/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className={'text-sm text-gray-600'}>User Name</label>
                                <input
                                    name="name"
                                    onChange={handleInput}
                                    defaultValue={data?.name || selectedUser?.name}
                                    type="text"
                                    className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                                    placeholder="Start from here"
                                />
                            </div>
                            <div className="mb-3">
                                <label className={'text-sm text-gray-600'}>User Email</label>
                                <input
                                    onChange={handleInput}
                                    name="username"
                                    type="email"
                                    defaultValue={selectedUser?.username || data?.username}
                                    className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                                    placeholder="user email address"
                                />
                            </div>
                            <div className="mb-2">
                                <label className={'text-sm text-gray-600'}>Phone Number</label>
                                <input
                                    onChange={handleInput}
                                    type="number"
                                    name="phone"
                                    defaultValue={data?.phone || selectedUser?.phone}
                                    className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                                    placeholder="User Phone No"
                                />
                            </div>
                            {!selectedUser.id &&<div className="mb-2">
                                <label className={'text-sm text-gray-600'}>Select Role</label>
                                <select className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                                        name={'roleTypeID'} onChange={handleInput}>
                                    <option>--Select a role--</option>
                                    <option value={'1'}>Admin</option>
                                    <option value={'2'}>User</option>
                                </select>
                            </div>}
                            {!selectedUser.id && <div className="mb-2">
                                <label className={'text-sm text-gray-600'}>PasswordUpdate</label>
                                <input
                                    onChange={handleInput}
                                    type="password"
                                    name="password"
                                    defaultValue={data?.password || selectedUser?.password}
                                    className="w-full focus:outline-none border p-2 rounded-xl bg-transparent text-sm"
                                    placeholder="User PasswordUpdate"
                                />
                            </div>}
                            <div className="mt-4">
                                <MButton type={'submit'} label={selectedUser?.id ? "Update User" : "Add User"}/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddUser