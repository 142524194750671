import "./BuyerCard.css"
import {baseUrl, formatTimeAgo} from "../../../utils/helper";
import React from "react";

const BuyerCard = ({buyer,index}) => {
    return (
        <div className="text-white my-5 mx-1 overflow-hidden cardWrap">
            <div  className={`card cardLeft p-3`}>
                <div className="flex">
                    <img src={`${baseUrl.url}${buyer?.image_url}`} alt="" className="w-8 h-8 rounded mr-2 object-cover" />
                    <div>
                        <h1 className='text-sm font-semibold'>{buyer?.name}</h1>
                        <p className={'text-xs -mt-1'}>{formatTimeAgo(buyer?.last_online)}</p>
                    </div>
                </div>
                <div className='mt-2 flex justify-between items-center'>
                    <p className='text-xs text-gray-700 tracking-wide'>Number Of Purchase</p>
                    <span className='text-gray-700 text-xl font-bold'>{buyer.no_of_purchase}</span>
                </div>
                <div className='flex justify-between items-center'>
                    <p className='text-xs text-gray-700 tracking-wide'>Purchased Product Qty</p>
                    <span className='text-gray-700 text-xl font-bold'>{buyer.purchased_product_quantity}</span>
                </div>
            </div>
            <div className="card cardRight p-0 text-center">
                <h1 className='text-5xl font-black tracking-tighter'>{index+1<10 && '0'}{index+1}</h1>
            </div>

        </div>
    )
}
export default BuyerCard
