import React, {useState} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import {baseUrl, errorNotify, successNotify} from "../../utils/helper";
import {FaEdit} from "react-icons/fa";
import useData from "../../hooks/useData";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import Empty from "../../Components/Empty/Empty";
import AddProductsLoader from "./AddProductsLoader";
import PageHeading from "../../Components/PageHeading/PageHeading";
import {
    useAddNewProductMutation,
    useDeleteProductMutation,
    useGetProductsQuery,
    useUpdateProductMutation
} from "../../rtk/api/productsApi";
import MButton from "../../Components/MButton/MButton";
import {Checkbox} from "primereact/checkbox";
import { ImSpinner2 } from "react-icons/im";
import MInput from "../../Components/MInput/MInput";

const AddProduct = () => {
    const {token} = useData();
    const [productData, setProductData] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    //INPUT STATE DATA ADD
    const handleInput = (e) => {
        setProductData({
            ...productData,
            [e.target.name]:
                e.target.type === "file" ? e.target.files[0] : e.target.value,
        });
    };
    const [addNewProduct, {isLoading}] = useAddNewProductMutation();
    const [deleteProduct] = useDeleteProductMutation();
    const [updateProduct] = useUpdateProductMutation();
    const {data,isLoading:loading,error} = useGetProductsQuery({url:'/api/product?page=0&size=100&sort=-created_at', token:token});
    const addProduct = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        for (const key in productData) {
            let element = productData[key];
            formData.append(`${key}`, element);
        }
        formData.append("isActive", 1);
        if (selectedProduct?.id) {
            try {
                await updateProduct({
                    url:`api/product/${selectedProduct?.id}`,
                    token:token,
                    data:formData
                }).unwrap();
                successNotify("Product Updated Successfully!");
                setProductData({});
                e.target.reset();
            }catch (e) {
                errorNotify(e.message)
            }
        } else {
            if (!productData.image_file) {
                errorNotify("Image required")
                return
            }
            if (!productData.name || productData.name.length < 5) {
                errorNotify("Minimum 5 letter required")
                return
            }
            if (!productData.quantity) {
                errorNotify("Quantity required")
                return
            }
            if (!productData.price) {
                errorNotify("Price required")
                return
            }
            try {
               await addNewProduct({data:formData,url:'/api/product',token:token}).unwrap()
                successNotify("Product Added Successfully!");
                setProductData({});
                e.target.reset();
            }catch (e) {
                errorNotify(e.message);
            }

        }
    };
    const handleCheckboxChange = (productId) => {
        if (selectedProductIds.includes(productId)) {
            setSelectedProductIds(
                selectedProductIds.filter((id) => id !== productId)
            );
        } else {
            setSelectedProductIds([...selectedProductIds, productId]);
        }
    };
    //MULTIPLE PRODUCT DELETE
    const handleDeleteSelected = async () => {
        const postData = selectedProductIds.map((id) => ({id}));
        try {
            await deleteProduct({
                token:token,
                data:JSON.stringify(postData),
                url:'/api/product'
            }).unwrap()
            successNotify("Product(s) Deleted Successfully!");
            setSelectedProductIds([])
        }catch (e) {
            errorNotify(e.message);
        }
    };
    const NameTemplate = (data) => {
        const name = data?.name;
        const imageURL = data?.imageURL;
        const id = data?.id;
        return (
            <div className="flex items-center text-sm lg:text-base">
                <Checkbox onChange={() => handleCheckboxChange(id)} checked={selectedProductIds.includes(id)}></Checkbox>
                <img
                    src={`${baseUrl.url}${imageURL}`}
                    alt=""
                    className="w-8 h-8 mx-2"
                />
                {name}
            </div>
        );
    };
    const ActionTemplate = (data) => {
        return (
            <div className="flex gap-1 items-center cursor-pointer">
                <FaEdit
                    className="hover:text-blue-500"
                    onClick={() => {
                        setSelectedProduct(data);
                    }}
                />
            </div>
        );
    };

    return (
        <div className="raleway mt-14">
            {isLoading && <div
                className='z-40 absolute top-0 left-0 h-full w-full flex bg-black opacity-10 items-center justify-center'>
                <div className='z-50 absolute top-0 left-0 h-full w-full flex items-center justify-center text-white'>
                    <ImSpinner2 className='spin360' size={35}/></div>
            </div>}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 lg:col-span-9 order-2 lg:order-1">

                    {
                        !error ? <>
                            {
                                !loading ? <div className='p-4'>
                                    <PageHeading title={'Product List'} sub={'A list of the products you can buy from the store'} btn={selectedProductIds.length>0 && 'Delete'} dltF={handleDeleteSelected}/>
                                    {
                                        data?.data?.items?.length>0 ? <div className="mt-3">
                                                <DataTable
                                                    value={data?.data?.items}
                                                    size={"small"}
                                                    dataKey="id"
                                                    tableStyle={{minWidth: "50rem"}}
                                                    sortMode="multiple"
                                                    paginator
                                                    rows={14}
                                                    rowsPerPageOptions={[14, 5, 10, 25, 50]}
                                                >
                                                    {/* <Column body={CheckboxTemplate} /> */}
                                                    <Column header="Name" body={NameTemplate}/>
                                                    <Column header="Qty" field="quantity"/>
                                                    <Column header="Price" field="price"/>
                                                    <Column header="Action" body={ActionTemplate}/>
                                                </DataTable>

                                                {/*{selectedProductIds?.length > 0 && (*/}
                                                {/*    <button*/}
                                                {/*        className="px-2 py-1 text-sm border bg-red-500 rounded cursor-pointer flex items-center justify-center gap-1 text-white font-semibold mt-3"*/}
                                                {/*        onClick={handleDeleteSelected}*/}
                                                {/*    >*/}
                                                {/*        Delete Product(s) <AiOutlineDelete className=""/>*/}
                                                {/*    </button>*/}
                                                {/*)}*/}
                                            </div>
                                            : <Empty/>
                                    }
                                </div> : <AddProductsLoader/>
                            }
                        </> : "error"
                    }
                </div>
                <div className="col-span-12 lg:col-span-3 order-1 lg:order-2">
                    <div className="p-4 lg:border-l h-auto lg:h-[92vh]">
                        <PageHeading title={'Add Product'} sub={'Add a picture and list the product\'s specifications.'}/>
                        <form onSubmit={addProduct}>
                            <div className="mb-2 mt-4">
                                <div
                                    className="h-32 w-32 mx-auto border-dashed border-2 border-gray-400 bg-gray-50 relative flex items-center justify-center rounded">
                                    {(productData?.image_file || selectedProduct?.imageURL) && (
                                        <img
                                            src={`${
                                                productData?.image_file
                                                    ? URL.createObjectURL(productData.image_file)
                                                    : `${baseUrl?.url}${selectedProduct?.imageURL}`
                                            }`}
                                            className="absolute top-0 left-0 w-full h-full "
                                            alt=""
                                        />
                                    )}
                                    <input
                                        onChange={handleInput}
                                        type="file"
                                        name="image_file"
                                        className="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                                    />
                                    <AiOutlinePlus size={50} className="text-gray-400"/>
                                </div>
                            </div>
                            <MInput name={'name'} label={'Product Name'} value={data?.name || selectedProduct?.name} func={handleInput} type={'text'} place={'Start from here'}/>
                            <MInput name={'quantity'} label={'Product Quantity'} value={selectedProduct?.quantity || data?.quantity} func={handleInput} type={'number'} place={'How many you want'}/>
                            <MInput name={'price'} label={'Product Price'} value={data?.price || selectedProduct?.price} func={handleInput} type={'number'} place={'Product Price'}/>
                            <MButton type={'submit'} classes={isLoading ? 'bg-black' : 'bg-blue-500'} label={selectedProduct?.id ? "Update Product" : "Add Product"}/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProduct;
